<template>
  <div class="container-3d">
    <div id="canvas3d">
      <div
        v-if="!hasModel3D && !installed"
        class="d-flex h-100 justify-center align-center flex-column img-preview"
      >
        <v-slide-x-transition v-if="!activeCanvas">
          <v-img
            v-show="!imgCustom"
            :key="zona?.id + color?.code"
            :src="imgUrl"
            :lazy-src="imgUrl"
            contain
            max-height="460px"
          >
            <template #placeholder>
              <v-row class="fill-height ma-0" align="center" justify="center">
                <v-progress-circular
                  indeterminate
                  color="primary lighten-5"
                ></v-progress-circular>
              </v-row>
            </template>
          </v-img>
        </v-slide-x-transition>
        <div
          v-if="!activeCanvas"
          v-show="imgCustom"
          class="w-100 custom-svg"
          v-html="imgCustom"
        ></div>
        <design-modal
          v-if="!hasModel3D && !installed"
          :id="zona?.id"
          :zona="zona"
          :dialog.sync="dialogDesign"
          dark
          @show-canvas="activeCanvas = true"
          @close-canvas="activeCanvas = false"
        ></design-modal>
        <v-btn class="mt-3" @click="load3D">Active 3d</v-btn>
      </div>
    </div>
    <v-container class="button-group" fluid>
      <v-row>
        <v-col class="w-100 pa-0">
          <cotizador-btn disabled content-class="w-100">
            <v-icon class="mr-3">mdi-vector-square</v-icon>
            Transformar
          </cotizador-btn>
        </v-col>
        <v-col class="w-100 pa-0">
          <cotizador-btn :disabled="!hasModel3D && !installed" content-class="w-100">
            <v-icon class="mr-3">mdi-arrow-all</v-icon>
            Posición
          </cotizador-btn>
        </v-col>
        <v-col v-if="!isYouReprocesos" class="w-100 pa-0">
          <cotizador-btn disabled content-class="w-100">
            <v-icon class="mr-3">mdi-image-outline</v-icon>
            Mis Diseños
          </cotizador-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="w-100 pa-0">
          <cotizador-btn
            :on="{
              click: () => {
                dialogDesign = true;
              }
            }"
            content-class="w-100"
            dark
          >
          {{ !activeCanvas ? "Cargar diseño" : $t('presupuesto.editCanvasTitle')  }}
            
          </cotizador-btn>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { noImgUrl } from "@/utils/imageUtils";
import designModal from "@/components/modales/designModal.vue";

import {
  init3DCanvas,
  loadColor,
  setActiveZone,
  uploadFile
} from "@/utils/customDimUtils";

import CotizadorBtn from "@/components/basic/CotizadorBtn.vue";

import { mapGetters } from "vuex";

export default {
  components: {
    CotizadorBtn,
    designModal
  },
  props: {
    color: {
      type: Object,
      validator: function (value) {
        return value?.code;
      },
      default: null
      // required: true
    },
    zona: {
      type: Object,
      validator: function (value) {
        return value?.Posicion && value?.Zona;
      },
      default: null
      // required: true
    },
    active: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      installed: false,
      json: null,
      activeZone3D: null,
      dialogDesign: false,
      activeCanvas: false,
    };
  },
  computed: {
    ...mapGetters("modelo", ["modelPrice", "newComputedImgUrl", "hasModel3D"]),
    ...mapGetters("config", ["isYouReprocesos"]),
    ...mapGetters("carrito", ["actualListDesign"]),
    imgUrl() {
      return this.newComputedImgUrl(this.zona, this.color?.code) ?? noImgUrl;
    },
    imgCustom: function () {
      let design = this.actualListDesign.find(
        d => d.id == this.zona.id && d.posicion == this.zona.Posicion
      );
      if (design && design.svg) return design.svg;
      return null;
    }
  },
  watch: {
    active(val) {
      if (val) {
        this.load3DWithChecks();
      }
    },
    async color(val) {
      if (this.installed) {
        await loadColor(val);

        if (this.zona?.Posicion) await setActiveZone(this.zona.Posicion);
      }
    },
    async selectedFile(val) {
      if (val && this.installed) {
        await uploadFile(val);
      }
    },
    async zona(val, oldVal) {
      if (val?.Posicion == oldVal?.Posicion) return;

      if (!val || !val?.Posicion) {
        console.warn("💻 ~ Necesitamos un valor de posicion correcto", val);
        return;
      }

      if (this.installed) {
        this.activeZone3D = await setActiveZone(val.Posicion);
      }
    }
  },
  methods: {
    async load3DWithChecks() {
      if (!this.modelPrice || !this.hasModel3D) return;

      this.load3D();
    },
    async load3D() {
      if (!this.installed) {
        try {
          await init3DCanvas(this.json, this.modelPrice.catalog.slug);
        } catch (err) {
          console.error(err);
        }
        console.log("vue - Instalation complete");
        this.installed = true;
      }

      await loadColor(this.color);

      setTimeout(async () => {
        this.activeZone3D = await setActiveZone(this.zona.Posicion);
      }, 1000);
    }
  }
};
</script>

<style lang="scss">
.container-3d {
  max-width: 100%;
  width: 100%;
  height: calc(100vh - 157px);
  max-height: 900px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .img-preview {
    .custom-svg {
      max-width: 460px;
    }
  }

  #canvas3d {
    max-height: 785px;
    height: 100%;
  }

  .button-group {
    // min-height: 96px;
    padding-bottom: 0px;
    .v-btn {
      border-radius: 0px !important;
    }
  }
}
</style>
