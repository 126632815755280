<template>
  <div class="btt-layout" :class="colorClass">
    <v-btn
      v-if="success"
      :class="classBtn"
      :loading="loading"
      :disabled="disabled"
      v-bind="bind"
      text
      class="cotizador-btn-success"
      v-on="on"
    >
      <slot>Button</slot>
    </v-btn>
    <v-btn
      v-else
      :loading="loading"
      :disabled="disabled"
      v-bind="bind"
      text
      class="cotizador-btn"
      :class="classBtn"
      v-on="on"
    >
      <slot>Button</slot>
    </v-btn>
  </div>
</template>

<script>
export default {
  name: "CotizadorBtn",
  components: {},
  filters: {},
  props: {
    success: {
      type: Boolean,
      default: false
    },
    bind: {
      type: Object,
      default: () => null
    },
    on: {
      type: Object,
      default: () => null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    animated: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    rounded: {
      type: Boolean,
      default: false
    },
    sm: {
      type: Boolean,
      default: false
    },
    contentClass: {
      type: String,
      default: ""
    },
    dark: {
      type: Boolean,
      default: false
    },
    outlined: {
      type: Boolean,
      default: null
    },
    colorBorder: {
      type: String,
      default: "white"
    }
  },
  data: () => ({}),
  computed: {
    colorClass: function () {
      return "btt-default";
    },
    //clases a aplicar al botón
    classBtn() {
      let actualClass = this.contentClass;

      if (this.sm) {
        actualClass += ` sm-btn`;
      }

      if (this.rounded) {
        actualClass += ` rounded`;
      }

      if (this.animated) {
        actualClass += " animated";
      }

      if (this.dark) {
        actualClass += " dark-btn";
      }

      if (this.outlined) {
        actualClass += ` outlined-btn`;
      }

      return actualClass;
    }
  },
  mounted: function () {},
  methods: {}
};
</script>

<style lang="scss">
.btt-layout {

  .v-btn.v-size--default {
    font-size: 0.775rem;
    max-width: 100%;

    span {
      text-wrap: wrap;
      max-width: 100%;
      padding-top: 3px;
      padding-bottom: 3px;
    }
  }
  .cotizador-btn {
    border: 1px solid rgba(0, 0, 0, 0.3);
    cursor: pointer;
    pointer-events: all;
    background: #f9f9f9;
    color: black;
    transition: all 0.3s;

    &.rounded {
      border-radius: 25px !important;
    }

    &.sm-btn {
      padding: 0.3rem .8rem;
      font-size: 0.72rem;
    }

    &.dark-btn {
      background: #333;
      color: white;

      &:hover {
        border: 1px solid var(--v-colorBtn-base);
        background-color: var(--v-colorBtn-base);
        color: var(--v-colorTextBtn-base);
      }
    }

    &.outlined-btn {
      border: 1px solid white;
      color: white;
      background: rgba(0, 0, 0, 0);

      &:hover {
        border: 1px solid var(--v-colorBtn-base);
        // background-color: #f9f9f9;
        color: var(--v-colorTextBtn-base);
      }
    }
  }
}

.cotizador-btn-success {
  border: 1px solid var(--v-colorBtn-base);
  background-color: var(--v-colorBtn-base);
  color: var(--v-colorTextBtn-base);

  &.rounded {
    border-radius: 25px !important;
  }
}

.cotizador-btn-success,
.cotizador-btn {
  &.animated {
    animation: blink-primary 2s linear infinite;
  }

  &.v-btn.v-btn--disabled {
    cursor: not-allowed;
    pointer-events: none;
    background: #f9f9f9;
    color: rgba(125, 125, 125, 0.7) !important;

    &::v-deep i.v-icon.v-icon--right.mdi.icon-cotizador-btn.v-icon--disabled {
      color: inherit !important;
    }
  }

  &:not(.v-btn--disabled):hover {
    // background: var(--v-colorBtn-lighten5);
    // background: rgba(var(--v-colorBtn-base), 0.7);
    background: color-mix(in srgb, var(--v-colorBtn-base) 50%, #f9f9f9);
  }
}

@keyframes blink-primary {
  0%,
  100% {
    background-color: white;
  }
  80% {
    background-color: var(--v-colorBtn-base);
  }
}
</style>
