import { fabric } from 'fabric';

/**
 * Configura las propiedades predeterminadas de los objetos fabric.
 */
fabric.Object.prototype.set({
    selectable: true,
    hasControls: true,
    hasBorders: true,
    borderColor: 'gray',
    // cornerColor: this.$vuetify.theme.currentTheme.primary,
    cornerSize: 7,
    transparentCorners: false
});

/**
 * Genera un SVG a partir de un JSON resultante de fabric.
 * 
 * @param {Object} json - El JSON que representa el diseño de fabric.
 * @returns {Promise<string>} - Una promesa que resuelve con el string SVG generado.
 */
const generateSvgFromJson = async (json) => {
    // Generamos un canvas estático (sin interacción) con el diseño combinado
    const staticCanvas = new fabric.StaticCanvas(null, { renderOnAddRemove: false });
    staticCanvas.setDimensions({ width: 500, height: 500 });

    try {

        let promise = new Promise((resolve, reject) => {
            try {
                staticCanvas.loadFromJSON(json, () => resolve());
            }
            catch (e) {
                reject(e);
            }
        });

        await promise

        // Genera el SVG a partir del canvas estático
        const svgString = staticCanvas.toSVG({
            suppressPreamble: true,
            width: "100%",
            height: "100%",
        });

        return svgString;
    } finally {
        // Dispose del canvas estático para liberar recursos
        staticCanvas.dispose();
    }
};

/**
 * Obtiene la imagen de fondo de un SVG.
 * 
 * @param {string} svg - El string SVG del cual se extraerá la imagen de fondo.
 * @returns {string|null} - La URL de la imagen de fondo o null si no se encuentra.
 */
const getBackgroundFromSvg = (svg) => {
    const parser = new DOMParser();
    const svgDocument = parser.parseFromString(svg, "image/svg+xml");
    const primerImage = svgDocument.querySelector("g image");
    return primerImage?.href?.baseVal;
};

/**
 * Establece una imagen de fondo en un SVG.
 * 
 * @param {string} svg - El string SVG en el cual se establecerá la imagen de fondo.
 * @param {string} background - La URL de la imagen de fondo a establecer.
 * @returns {string} - El string SVG modificado con la nueva imagen de fondo.
 */
const setBackgroundToSvg = (svg, background) => {
    const parser = new DOMParser();
    // Tenemos que modificar el svg
    let svgDocument = parser.parseFromString(svg, "image/svg+xml");
    const primerImage = svgDocument.querySelector("g image");
    if (primerImage) {
        primerImage.setAttribute("href", background);
        primerImage.setAttribute("xlink:href", background);
        svg = svgDocument.documentElement.outerHTML;
        return svg;
    } else {
        console.warn("No hemos podido modificar modificar la imagen de background");
    }
}

/**
 * Intenta generar una zona de fusión a partir de una lista de diseños.
 * 
 * @param {Object} listNumForEachPosition - Un objeto que mapea posiciones a números de diseños.
 * @param {Object} numDesignForEachPreviewRule - Un objeto que mapea reglas de vista previa a números de diseños.
 * @param {Array} listDesign - Una lista de diseños.
 * @returns {Promise<string|null>} - Una promesa que resuelve con el string SVG generado o null si no se puede generar.
 */
const tryGenerateMergeZones = async (listNumForEachPosition, numDesignForEachPreviewRule, listDesign) => {
    // Vemos que zona tiene mayor número de diseños, para mergearlo y usarlo
    let zonaToUse = null;
    let numDesign = 0;
    Object.keys(listNumForEachPosition).forEach(z => {
        if (!zonaToUse || listNumForEachPosition[z] > numDesign) {
            zonaToUse = z;
            numDesign = listNumForEachPosition[z];
        }
    });

    let previewRule = null;
    Object.keys(numDesignForEachPreviewRule).forEach(z => {
        if (!zonaToUse || numDesignForEachPreviewRule[z] > numDesign) {
            previewRule = z;
            numDesign = numDesignForEachPreviewRule[z];
        }
    });

    const hasZoneDesign = zonaToUse && listNumForEachPosition[zonaToUse] > 1;
    const hasPreviewRule = previewRule && numDesignForEachPreviewRule[previewRule] > 1
    if (hasZoneDesign || hasPreviewRule) {
        // De la lista de filtrados nos quedamos únicamente con los que tienen la misma posicion
        let filteredList = listDesign.filter(x => hasPreviewRule ? x.previewRule == previewRule : x.zona == zonaToUse);

        // Si no hay ninguno salimos
        if (filteredList.length != 0) {
            let canvasJson = filteredList.reduce((acc, curr) => {
                acc.objects = acc.objects.concat(curr.canvasJson.objects);
                return acc;
            }, { ...filteredList[0].canvasJson, svgOriginal: filteredList[0].svg });

            let svg = await generateSvgFromJson(canvasJson);

            let imageBg = getBackgroundFromSvg(canvasJson.svgOriginal);
            if (canvasJson.backgroundImage.src != imageBg) {
                svg = setBackgroundToSvg(svg, imageBg);
            }
            return svg;
        }
    }

    return null;
}

export { fabric, generateSvgFromJson, tryGenerateMergeZones, getBackgroundFromSvg, setBackgroundToSvg };