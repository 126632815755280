<template>
  <div class="pl-3">
    <v-dialog
      v-model="dialog"
      :fullscreen="!isYouReprocesos"
      :hide-overlay="!isYouReprocesos"
      transition="dialog-bottom-transition"
      class="dialog-zindex"
      :content-class="'manipuladosEtiquetasModal'+ (isYouReprocesos ? ' youReprocesosProductModal' : '')"
    >
      <template #activator="{ on }">
        <div>
          <CotizadorBtn :on='on' :disabled="disabled || totalUnidades <= 0">
            {{ boton }}
          </CotizadorBtn>
        </div>
      </template>
      <v-card id="manipuladosDialogBody">
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="closeDialog">
            <v-icon>mdi-progress-close</v-icon>
          </v-btn>
          <v-toolbar-title>
            {{ $t("configuracion.ReprocesosGenerales.Titulo") }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <CotizadorBtn
            :disabled="
              !(ManipuladosParaAnadir.length > 0 || etiquetasParaAnadir.length > 0)
            "
            dark
            color="primary darken-2"
            :on='{click: closeDialog}'
          >
            {{ $t("configuracion.ReprocesosGenerales.Añadir a carrito") }}
          </CotizadorBtn>
        </v-toolbar>

        <v-fade-transition>
          <v-overlay v-if="cargando" absolute>
            <v-progress-circular
              indeterminate
              color="primary"
            ></v-progress-circular>
          </v-overlay>
        </v-fade-transition>
        <div v-if="ComprobarManipulados == true">
          <v-subheader class="subheader">
            {{ $t("configuracion.ReprocesosGenerales.Manipulados") }}:
            {{ totalManipulados | filtrarPrecios }}{{ currencySymbol }}
          </v-subheader>
          <v-divider></v-divider>
          <v-list three-line subheader>
            <v-list-item>
              <v-list-item-content>
                <v-row>
                  <v-col cols="7" class="justify-center">
                    <v-select
                      v-model="manipulados"
                      class="compact-form-input"
                      :items="manipulaciones"
                      filled
                      prepend-inner-icon="mdi-hand-right"
                      color="primary darken-2"
                      :hint="
                        $t(
                          'configuracion.ReprocesosGenerales.Seleccionemanipulados'
                        )
                      "
                      persistent-hint
                    ></v-select>
                  </v-col>
                  <v-col class="margin-left justify-center" cols="3">
                    <v-row
                      v-for="(elem, index) in prendasManipulado"
                      :key="index"
                    >
                      <v-col>
                        <v-checkbox
                          v-model="elem.check"
                          class="checkbox-prendas"
                          :label="
                            $t(`SIZES.${elem.talla}`) +
                            ` - ` +
                            $t(`COLORS.${elem.color}`) +
                            ` (` +
                            elem.id +
                            `)`
                          "
                        ></v-checkbox>
                      </v-col>
                      <v-col>
                        <v-text-field
                          v-model="elem.cantidad"
                          class="input-prendas"
                          :max="elem.cantidad"
                          :disabled="!elem.check"
                          min="1"
                          filled
                          type="number"
                          :hint="
                            $t('configuracion.ReprocesosGenerales.Prendas')
                          "
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>

                  <v-col class="d-flex justify-left bottom-add" cols="1">
                    <v-btn
                      dark
                      color="primary darken-2"
                      :class="cargandomani ? 'disabled' : ''"
                      @click="anadirManipulado"
                    >
                      <v-icon>mdi-plus</v-icon>
                      <v-fade-transition>
                        <v-overlay v-if="cargandomani" absolute>
                          <v-progress-circular
                            indeterminate
                            color="gray"
                          ></v-progress-circular>
                        </v-overlay>
                      </v-fade-transition>
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="8">
                    <tablaSimple
                      v-if="ManipuladosParaAnadir.length > 0"
                      id="tablaResumen"
                      :headers="headersManipulados"
                      :productos-tabla="ManipuladosParaAnadir"
                      :referencia="titulo1"
                      @deleteItem="borrarManipuladoParaAnadir"
                    />
                  </v-col>
                </v-row>
              </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>
          </v-list>
        </div>
        <div v-if="Comprobaretiquetas == true" class="etiquetas-section">
          <alert
            :dialog="aletar"
            :titulo="
              $t('configuracion.ReprocesosGenerales.TituloAdvertSerigrafia')
            "
            :cuerpo="
              $t('configuracion.ReprocesosGenerales.CuerpoAdvertSerigrafia')
            "
            :boton-cerrar="
              $t('configuracion.ReprocesosGenerales.BotonAdvertSerigrafia')
            "
            @cerrar="cerrar"
          ></alert>
          <v-subheader class="subheader">
            {{ $t("configuracion.ReprocesosGenerales.Etiquetas") }}:
            {{ totalEtiquetas | filtrarPrecios }}{{ currencySymbol }}
          </v-subheader>
          <v-divider></v-divider>
          <v-list three-line subheader>
            <v-list-item>
              <v-list-item-content>
                <v-row>
                  <v-col cols="2" class="justify-center">
                    <v-select
                      v-model="tipoEtiquetaSeleccionada"
                      class="compact-form-input"
                      :items="tipoEtiqueta"
                      filled
                      prepend-inner-icon="mdi-hand-right"
                      color="primary darken-2"
                      :hint="
                        $t(
                          'configuracion.ReprocesosGenerales.SeleccioneTipoEtiqueta'
                        )
                      "
                      persistent-hint
                    ></v-select>
                  </v-col>
                  <v-col id="Columnatoltip">
                    <v-tooltip top>
                      <template #activator="{ on, attrs }">
                        <v-btn icon v-bind="attrs" v-on="on">
                          <v-icon>mdi-information</v-icon>
                        </v-btn>
                      </template>
                      <span>
                        {{
                          $t(
                            "configuracion.ReprocesosGenerales.WarningEtiquetas"
                          )
                        }}
                      </span>
                    </v-tooltip>
                  </v-col>
                  <v-col class="justify-center" cols="1">
                    <v-text-field
                      v-model="numetiquetas"
                      class="compact-form-input"
                      :max="prendas"
                      min="1"
                      filled
                      type="number"
                      :hint="$t('configuracion.ReprocesosGenerales.Etiquetas')"
                      persistent-hint
                    ></v-text-field>
                  </v-col>

                  <v-col v-if="tipoEtiquetaSeleccionada != 2" class="columnaMininput" cols="1">
                    <v-select
                      v-model="colorEtiqueta"
                      class="compact-form-input"
                      :items="
                        tipoEtiquetaSeleccionada == 0
                          ? coloresEtiquetas
                          : coloresEtiquetaSerigrafia
                      "
                      filled
                      prepend-inner-icon="mdi-format-color-fill"
                      color="primary darken-2"
                      :hint="
                        tipoEtiquetaSeleccionada == 0
                          ? $t(
                              'configuracion.ReprocesosGenerales.ColoresTransfer'
                            )
                          : $t(
                              'configuracion.ReprocesosGenerales.ColoresSerigrafia'
                            )
                      "
                      persistent-hint
                    ></v-select>
                  </v-col>
                  <v-col
                    v-if="
                      tipoEtiquetaSeleccionada == 0 ||
                      tipoEtiquetaSeleccionada == 2
                    "
                    class="columnaMininput"
                    cols="1"
                  >
                    <v-select
                      v-model="tamanoSeleccionado"
                      class="compact-form-input"
                      :items="tamanoEtiquetasTransfer"
                      filled
                      prepend-inner-icon="mdi-format-size"
                      color="primary darken-2"
                      :hint="
                        $t('configuracion.ReprocesosGenerales.Tamaño estampado')
                      "
                      persistent-hint
                    ></v-select>
                  </v-col>
                  <v-col>
                    <v-row>
                      <v-col v-if="tipoEtiquetaSeleccionada != 2" cols="2">
                        <v-checkbox
                          v-model="ImprimirTalla"
                          class="checkbox-Etiquetas"
                          :label="
                            $t(
                              'configuracion.ReprocesosGenerales.Imprimir Talla'
                            )
                          "
                        />
                        <v-checkbox
                          v-model="ImprimirDiseno"
                          class="checkbox-Etiquetas"
                          :label="
                            $t(
                              'configuracion.ReprocesosGenerales.Imprimir diseño'
                            )
                          "
                        />
                      </v-col>
                      <v-col cols="2">
                        <v-checkbox
                          v-model="esRepeticion"
                          class="checkbox-Etiquetas"
                          :label="
                            $t(
                              'configuracion.ReprocesosGenerales.Es repeticion'
                            )
                          "
                        />
                      </v-col>
                      <v-col cols="1" class="d-flex justify-center align-center">
                        <v-btn
                          dark
                          color="primary darken-2"
                          :class="cargandoetiq ? 'disabled' : ''"
                          @click="anadiretiqueta"
                        >
                          <v-icon>mdi-plus</v-icon>
                          <v-fade-transition>
                            <v-overlay v-if="cargandoetiq" absolute>
                              <v-progress-circular
                                indeterminate
                                color="gray"
                              ></v-progress-circular>
                            </v-overlay>
                          </v-fade-transition>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col
                    class="d-flex justify-left bottom-add"
                    cols="1"
                  ></v-col>
                </v-row>
                <v-row>
                  <v-col cols="8">
                    <tablaSimple
                      v-if="etiquetasParaAnadir.length > 0"
                      id="tablaResumen"
                      :headers="headersEtiquetas"
                      :productos-tabla="etiquetasParaAnadir"
                      :referencia="tituloEtiq1"
                      @deleteItem="borrarEtiquetaParaAnadir"
                    />
                  </v-col>
                </v-row>
              </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>
          </v-list>
        </div>
        <div v-if="tecnicasGenerales.length <= 0" class="mt-3 ml-3">
          <strong v-if="!isReprocesosStm">
            {{ $t("configuracion.ReprocesosGenerales.SinReprocesos") }}
          </strong>
          <strong v-else-if="isReprocesosStm">
            {{
              $t("configuracion.ReprocesosGenerales.ManipuladosEspecialesStm")
            }}
          </strong>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import Alert from "../modales/alert.vue";
import tablaSimple from "../tablas/tablaClientSideNoPagingWithDelete.vue";
import CotizadorBtn from "../basic/CotizadorBtn.vue";
export default {
  name: "ReprocesosGenerales",
  components: {
    tablaSimple,
    Alert,
    CotizadorBtn
  },
  props: {
    prendas: Number,
    disabled: Boolean
  },
  data() {
    return {
      cargando: false,
      cargandomani: false,
      cargandoetiq: false,
      dialog: false,
      aletar: false,
      titulo1: this.$t("configuracion.ReprocesosGenerales.Manipulado"),
      tituloEtiq1: this.$t("configuracion.ReprocesosGenerales.Etiqueta"),
      totalManipulados: 0.0,
      Modelo: null,
      manipulaciones: [],
      manipulados: null,
      prendasManipulado: [],
      headersManipulados: [
        {
          text: this.titulo1,
          align: "start",
          sortable: false,
          value: "Manipulado",
          class: "grey darken-2 white--text white--text columnatablaprendas"
        },
        {
          text: this.$t("configuracion.ReprocesosGenerales.PrendasTitulo"),
          align: "start",
          sortable: false,
          value: "Prendas",
          class: "grey darken-2 white--text white--text columnatablaprendas"
        },
        {
          text: this.$t("configuracion.ReprocesosGenerales.precio"),
          align: "start",
          sortable: false,
          value: "precio",
          class: "grey darken-2 white--text white--text columnatablaprendas"
        },
        {
          text: this.$t("configuracion.ReprocesosGenerales.Eliminar"),
          value: "actions",
          sortable: false,
          class: "grey darken-2 white--text white--text"
        }
      ],
      ManipuladosParaAnadir: [],

      totalEtiquetas: 0.0,
      tipoEtiqueta: [
        /*{
          text: this.$t("configuracion.ReprocesosGenerales.Transfer"),
          value: 0,
        },*/
        /*{
          text: this.$t("configuracion.ReprocesosGenerales.Serigrafía"),
          value: 1
        },*/
        {
          text: this.$t("configuracion.ReprocesosGenerales.DTF"),
          value: 2
        }
      ],
      tipoEtiquetaSeleccionada: 2,
      numetiquetas: this.prendas,
      coloresEtiquetas: [1, 2, 3, 4, 5, 6, 7, 8],
      colorEtiqueta: 1,
      coloresEtiquetaSerigrafia: [
        { text: this.$t("configuracion.ReprocesosGenerales.Gris"), value: 1 },
        { text: this.$t("configuracion.ReprocesosGenerales.Blanco"), value: 2 },
        { text: this.$t("configuracion.ReprocesosGenerales.Plata"), value: 3 }
      ],
      tamanoEtiquetasTransfer: [],
      tamanoSeleccionado: 1,
      ImprimirTalla: false,
      ImprimirDiseno: false,
      esRepeticion: false,
      etiquetasParaAnadir: [],
      headersEtiquetas: [
        {
          text: this.tituloEtiq1,
          align: "start",
          sortable: false,
          value: "Tecnica",
          class: "grey darken-2 white--text white--text columnatablaprendas"
        },
        {
          text: this.$t("configuracion.ReprocesosGenerales.Numero"),
          align: "start",
          sortable: false,
          value: "Numero",
          class: "grey darken-2 white--text white--text columnatablaprendas"
        },
        {
          text:
            this.tipoEtiquetaSeleccionada == 1
              ? this.$t("configuracion.ReprocesosGenerales.Color")
              : this.$t("configuracion.ReprocesosGenerales.Colores"),
          align: "start",
          sortable: false,
          value: "Colores",
          class: "grey darken-2 white--text white--text columnatablaprendas"
        },
        {
          text: this.$t("configuracion.ReprocesosGenerales.Imprimir Talla"),
          align: "start",
          sortable: false,
          value: "talla",
          class: "grey darken-2 white--text white--text columnatablaprendas"
        },
        {
          text: this.$t("configuracion.ReprocesosGenerales.Imprimir diseño"),
          align: "start",
          sortable: false,
          value: "diseno",
          class: "grey darken-2 white--text white--text columnatablaprendas"
        },
        {
          text: this.$t("configuracion.ReprocesosGenerales.Es repeticion"),
          align: "start",
          sortable: false,
          value: "repeticion",
          class: "grey darken-2 white--text white--text columnatablaprendas"
        },
        {
          text: this.$t("configuracion.ReprocesosGenerales.precio"),
          align: "start",
          sortable: false,
          value: "precio",
          class: "grey darken-2 white--text white--text columnatablaprendas"
        },
        {
          text: this.$t("configuracion.ReprocesosGenerales.Eliminar"),
          value: "actions",
          sortable: false,
          class: "grey darken-2 white--text white--text"
        }
      ],
      siguienteID: 0
    };
  },
  computed: {
    ...mapGetters("carrito", ["carrito", "hasPackUno", "manipuladosPrices", "totalUnidades"]),
    ...mapGetters("modelo", ["currencySymbol", "isReprocesosStm", "hasManipulados", "hasTransfer", "hasDtf", "hasSerigrafia", "modelCode", "disableTecnicasButton"]),
    ...mapGetters("config", ["isProveedor", "key", "isYouReprocesos"]),
    listManipulados: function () {
      return this.ManipuladosParaAnadir.map((elem, index) => {
        return {
          Id: elem.id,
          Index: index,
          Products: elem.listInfoProduct.map(pre => {
            return {
              Code: pre.nombre,
              Quantity: pre.cantidad
            };
          })
        };
      });
    },
    tecnicasGenerales: function () {
      var tec = [];
      if (this.hasManipulados) {
        let id = "0";
        let tecnica = "Manipulado";
        let nombre = this.$t("Tecnicas.MANIPULADOS");
        tec.push({
          id: id,
          tecnica: tecnica,
          nombre: nombre
        });
      }

      if (!this.disableTecnicasButton && (this.hasTransfer || this.hasDtf || this.hasSerigrafia)) {
        let id = "1";
        let tecnica = "Etiquetas";
        let nombre = this.$t("Tecnicas.ETIQUETAS");
        tec.push({
          id: id,
          tecnica: tecnica,
          nombre: nombre
        });
      }
      return tec;
    },
    boton: function () {
      if (this.Comprobaretiquetas && this.ComprobarManipulados) {
        return (
          this.$t("Tecnicas.MANIPULADOS") +
          " & " +
          this.$t("Tecnicas.ETIQUETAS")
        );
      } else if (this.Comprobaretiquetas) {
        return this.$t("Tecnicas.ETIQUETAS");
      } else if (this.ComprobarManipulados) {
        return this.$t("Tecnicas.MANIPULADOS");
      } else
        return this.$t("configuracion.ReprocesosGenerales.AñadirReprocesos");
    },
    ComprobarManipulados() {
      return (
        this.tecnicasGenerales.findIndex(element => element.id == "0") !== -1 &&
        this.hasManipulados
      );
    },
    Comprobaretiquetas() {
      return (
        this.tecnicasGenerales.findIndex(element => element.id == "1") !== -1
      );
    }
  },
   watch: {
    dialog: function () {
      this.updateHeightFrame();
    },
    modelCode: function () {
      this.buscarmanipulaciones();
      this.buscarTamanos();
    },
    ComprobarManipulados: function () {
      this.buscarmanipulaciones();
    },
    Comprobaretiquetas: function () {
      this.buscarTamanos();
    },
    prendas: function () {
      this.setArrays();
      this.numetiquetas = this.prendas;
    },
    tipoEtiquetaSeleccionada: function (newvalue, oldvalue) {
      if (newvalue == oldvalue) return;
      this.colorEtiqueta = 1;
      if (this.tipoEtiquetaSeleccionada == 1) {
        this.aletar = true;
      } else {
        this.aletar = false;
      }
    },
    key: function () {
      this.buscarmanipulaciones();
      this.buscarTamanos();
      this.setArrays();
      this.numetiquetas = this.prendas;
    },
    numetiquetas: function () {},
    colorEtiqueta: function () {}
  },
  async mounted() {
    await this.buscarmanipulaciones();
    await this.buscarTamanos();

    if (!this.prendasManipulado || this.prendasManipulado.length == 0) {
      this.setArrays();
    }

    this.etiquetasParaAnadir = this.carrito.reprocesos.filter(
      r => r.isEtiqueta
    );
  },
  methods: {
    ...mapActions("carrito", [
      "deleteReproceso",
      "calcularPrecios",
      "borrarManipulados"
    ]),
    ...mapActions("modelo", ["getmanipulaciones"]),
    ...mapActions("config", ["updateHeightFrame"]),
    closeDialog() {
      this.emitClick(false);
    },
    emitClick(payload = false) {
      console.log("🚀 ~ emitClick ~ payload:", payload)
      if (this.disabled || this.totalUnidades <= 0) 
      {
        this.dialog = false;
        return;
      }
      this.dialog = payload;
      parent.postMessage({ type: "scrollTop" }, "*");
      this.$emit("click", payload);
    },
    anadirManipulado: async function () {
      this.cargandomani = true;
      var obj = new Object();
      let listInfoProduct = [];
      var manip = this.manipulaciones.find(
        word => word.value == this.manipulados
      );
      obj.id = manip.value;
      obj.Manipulado = manip.text;
      obj.Prendas = 0;
      let maxCantidad = 1;
      this.prendasManipulado.forEach(elem => {
        if (elem.check == true) {
          elem.cantidad = elem.cantidad > elem.max ? elem.max : elem.cantidad;
          obj.Prendas = obj.Prendas + parseInt(elem.cantidad);
          var producto = new Object();
          producto.nombre = elem.id;
          producto.cantidad = elem.cantidad;
          if (maxCantidad < producto.cantidad) maxCantidad = producto.cantidad;
          listInfoProduct.push(producto);
        }
      });
      obj.prendasManipulado = this.prendasManipulado;
      obj.listInfoProduct = listInfoProduct;
      this.cargando = true;

      //this.getmanipulaciones(peticion);

      this.borrarManipulados();
      let parametros = {
        tecnica: "Manipulacion",
        codigo: this.modelCode,
        posicion: 1,
        zona: "FRONTAL",
        unidades: maxCantidad,
        isRepeticion: this.esRepeticion,

        atributos: JSON.stringify([
          {
            isProveedor: this.isProveedor,
            listManipulados: JSON.stringify([
              ...this.listManipulados,
              {
                Id: obj.id,
                Index: this.ManipuladosParaAnadir.length + 1,
                Products: listInfoProduct.map(pre => {
                  return {
                    Code: pre.nombre,
                    Quantity: pre.cantidad
                  };
                })
              }
            ]),
            esPrendaPackUno: this.id == 0 ? this.hasPackUno : false
          }
        ]),

        isManipulado: true,
        id: "Manipulados"
      };

      await this.calcularPrecios(parametros);
      this.totalManipulados = this.manipuladosPrices.costes.Total;
      obj.frontId = this.ManipuladosParaAnadir.length + 1;
      let response = JSON.parse(this.manipuladosPrices.costes.manipuladoPrecio);
      obj.precio = response[obj.frontId] + this.currencySymbol;

      this.cargando = false;
      this.ManipuladosParaAnadir.push(obj);
      this.cargandomani = false;
    },
    borrarManipuladoParaAnadir: async function (item) {
      if (
        this.ManipuladosParaAnadir.find(word => word.frontId == item.frontId)
      ) {
        for (var i = 0; i < this.ManipuladosParaAnadir.length; i++) {
          if (this.ManipuladosParaAnadir[i].frontId == item.frontId) {
            this.totalManipulados =
              this.totalManipulados -
              parseFloat(this.ManipuladosParaAnadir[i].precio);
            this.ManipuladosParaAnadir.splice(i, 1);
            this.borrarManipulados();

            if (this.ManipuladosParaAnadir.length > 0) {
              let parametros = {
                tecnica: "Manipulacion",
                codigo: this.modelCode,
                posicion: 1,
                zona: "FRONTAL",
                unidades: 1,
                isRepeticion: this.esRepeticion,

                atributos: JSON.stringify([
                  {
                    isProveedor: this.isProveedor,
                    listManipulados: JSON.stringify(this.listManipulados),
                    esPrendaPackUno: this.id == 0 ? this.hasPackUno : false
                  }
                ]),

                isManipulado: true,
                id: "Manipulado"
              };

              await this.calcularPrecios(parametros);
            }

            break;
          }
        }
        if (
          this.ManipuladosParaAnadir.length <= 0 &&
          this.carrito.reprocesos.find(
            word => word["tecnica"] != null && word.tecnica == "Manipulacion"
          )
        ) {
          this.borrarManipulados();
        }
      }
    },

    buscarmanipulaciones: async function () {
      if (!this.ComprobarManipulados) return;
      var respuesta = await this.getmanipulaciones({
        c: this.modelCode,
        k: this.key
      });
      var primero = true;
      this.manipulaciones = [];
      respuesta?.data?.manipulados?.forEach(element => {
        this.manipulaciones.push({ text: element.nombre, value: element.id });
        if (primero) {
          primero = false;
          this.manipulados = element.id;
        }
      });
    },
    setArrays: function () {
      this.prendasManipulado = [];
      this.carrito.productos.forEach(elem => {
        var obj = new Object();
        obj.cantidad = parseInt(elem.cantidad);
        obj.check = true;
        obj.id = elem.id;
        obj.talla = elem.talla;
        obj.color = elem.color;
        obj.max = parseInt(elem.cantidad);
        this.prendasManipulado.push(obj);
      });
    },
    tamañosIniciales: function () {
      return [
        { text: "A8", value: "A8", Ancho: 52, Alto: 74 },
        { text: "A10", value: "A10", Ancho: 26, Alto: 37 }
      ];
    },
    buscarTamanos: async function () {
      if (!this.Comprobaretiquetas) return;
      var primero = true;
      this.tamañosIniciales().forEach(element => {
        this.tamanoEtiquetasTransfer.push({
          text: element.text,
          value: element.value
        });
        if (primero) {
          primero = false;
          this.tamanoSeleccionado = element.value;
        }
      });
    },
    anadiretiqueta: async function () {
      this.cargandoetiq = true;
      var obj = new Object();
      obj.id = "Etiquetas-" + this.siguienteID;
      obj.params = new Object();
      if (
        this.tipoEtiquetaSeleccionada == 0 ||
        this.tipoEtiquetaSeleccionada == 2
      ) {
        obj.params.codigoModelo = this.modelCode;
        obj.params.colores = 1;
        obj.params.prendas = this.numetiquetas;
        obj.params.idTamano = this.tamanoSeleccionado;
        obj.params.idTipoTinta = 3;
        obj.params.esPrendaPackUno = false;
        obj.params.esRepeticion = this.esRepeticion;
        obj.params.isProveedor = this.isProveedor;

        let parametros = {
          tecnica:
            this.tipoEtiquetaSeleccionada == 0
              ? "TransferEtiquetas"
              : "DTFEtiquetas",
          codigo: this.modelCode,
          posicion: 1,
          zona: "FRONTAL",
          unidades: this.numetiquetas,
          isRepeticion: this.esRepeticion,

          atributos: JSON.stringify([
            {
              isProveedor: this.isProveedor,
              tamano: this.tamanoSeleccionado,
              esPrendaPackUno: false,
              colores: 1,
              idTipoTinta: 3,
              imprimirTalla: this.tipoEtiquetaSeleccionada == 0 && this.ImprimirTalla,
              imprimirDiseno: this.tipoEtiquetaSeleccionada == 0 && this.ImprimirDiseno,
              idFront: this.siguienteID
            }
          ]),

          isEtiqueta: true,
          id: obj.id
        };
        this.siguienteID = this.siguienteID + 1;

        let response2 = await this.calcularPrecios(parametros);

        if (response2) {
          obj.precio =
            (await parseFloat(response2.data.costes.Total)) +
            this.currencySymbol;
          this.totalEtiquetas =
            parseFloat(this.totalEtiquetas) +
            parseFloat(response2.data.costes.Total);
        }
      } else {
        obj.params.tallas = this.carrito.productos.length;
        obj.params.esRepeticion = this.esRepeticion;
        obj.params.cambioTintaColor = false;
        obj.params.cambioTalla = false;
        obj.params.etiquetas = this.numetiquetas;
        obj.params.isProveedor = this.isProveedor;

        let parametros = {
          tecnica: "SerigrafiaEtiquetas",
          codigo: this.modelCode,
          posicion: 1,
          zona: "FRONTAL",
          unidades: this.numetiquetas,
          isRepeticion: this.esRepeticion,

          atributos: JSON.stringify([
            {
              isProveedor: this.isProveedor,
              tallas: this.carrito.productos.length,
              imprimirTalla: this.ImprimirTalla,
              imprimirDiseno: this.ImprimirDiseno,
              color: this.colorEtiqueta,
              idFront: this.siguienteID
            }
          ]),

          isEtiqueta: true,
          id: obj.id
        };
        this.siguienteID = this.siguienteID + 1;

        let response2 = await this.calcularPrecios(parametros);

        if (response2) {
          obj.precio =
            (await parseFloat(response2.data.costes.total)) +
            this.currencySymbol;
          this.totalEtiquetas =
            parseFloat(this.totalEtiquetas) +
            parseFloat(response2.data.costes.total);
        }
      }

      obj.TecnicaEtiqueta = this.tipoEtiquetaSeleccionada;
      obj.Tecnica =
        this.tipoEtiquetaSeleccionada == 0
          ? "Transfer"
          : this.tipoEtiquetaSeleccionada == 1
            ? "Serigrafía"
            : "DTF";
      obj.NumeroEtiquetas = this.numetiquetas;
      obj.Numero =
        this.tipoEtiquetaSeleccionada == 0 || this.tipoEtiquetaSeleccionada == 2
          ? this.numetiquetas +
            "-" +
            this.tamanoEtiquetasTransfer.find(
              e => e.value == this.tamanoSeleccionado
            ).text
          : this.numetiquetas;
      obj.tamanoEtiqueta = this.tamanoSeleccionado;
      obj.ColoresEtiqueta = this.colorEtiqueta;
      obj.Colores = this.tipoEtiquetaSeleccionada == 2 ? "-" :
        this.tipoEtiquetaSeleccionada == 0
          ? this.colorEtiqueta
          : this.coloresEtiquetaSerigrafia.find(
              e => e.value == this.colorEtiqueta
            ).text;
      obj.diseno = this.tipoEtiquetaSeleccionada == 2 ? "-" : this.tipoEtiquetaSeleccionada != 2 && this.ImprimirDiseno ? "Sí" : "No";
      obj.talla = this.tipoEtiquetaSeleccionada == 2 ? "-" : this.tipoEtiquetaSeleccionada != 2 && this.ImprimirTalla ? "Sí" : "No";
      obj.repeticion = this.esRepeticion ? "Sí" : "No";

      obj.type =
        this.tipoEtiquetaSeleccionada == 0
          ? "TransferEtiquetas"
          : this.tipoEtiquetaSeleccionada == 1
            ? "SerigrafiaEtiquetas"
            : "DTFEtiquetas";
      this.etiquetasParaAnadir.push(obj);
      this.cargandoetiq = false;
    },
    borrarEtiquetaParaAnadir: function (item) {
      if (this.etiquetasParaAnadir.find(word => word.id == item.id)) {
        for (var i = 0; i < this.etiquetasParaAnadir.length; i++) {
          if (this.etiquetasParaAnadir[i].id == item.id) {
            this.totalEtiquetas =
              this.totalEtiquetas -
              parseFloat(this.etiquetasParaAnadir[i].precio);
            this.etiquetasParaAnadir.splice(i, 1);
            break;
          }
        }
      }
      if (
        this.carrito.reprocesos.find(
          word => word["id"] != null && word.id == item.id
        )
      ) {
        for (var j = 0; j < this.carrito.reprocesos.length; j++) {
          if (
            this.carrito.reprocesos[j]["id"] != null &&
            this.carrito.reprocesos[j].id == item.id
          ) {
            this.deleteReproceso(this.carrito.reprocesos[j]);
            break;
          }
        }
      }
    },
    cerrar: async function () {
      this.aletar = false;
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.dialog-zindex {
  z-index: 1000;
}

.manipuladosEtiquetasModal {
  margin-top: 83px !important;

  &.youReprocesosProductModal {
    margin-top: 12px !important;
    position: absolute;
    top: 65px;
    max-width: 1640px;

    #manipuladosDialogBody {
      max-height: 956px;
      min-height: 760px;
    }
  }
}
#botonReprocesos {
  font-size: 15px;
}
.subheader {
  font-size: 20px;
  font-weight: 1000;
}

.bottom-add {
  padding-top: 1.5%;
}
.margin-left {
  margin-left: -5%;
}
.compact-form-input {
  transform: scale(0.85);
  transform-origin: left;
}
.input-prendas {
  max-width: 100px;
  min-width: 75px;
  transform: scale(0.8);
  transform-origin: left;
  margin-bottom: -20%;
  margin-left: -5%;
}
.checkbox-prendas {
  /* width: 175px; */
  margin-bottom: -10%;
}
.checkbox-Etiquetas {
  min-width: 300px;
}
#tablaResumen {
  width: 80%;
}
.columnatablaprendas {
  width: 50%;
}
#Columnatoltip {
  max-width: 10px !important;
  margin-right: 1%;
  margin-left: -1%;
}
.columnaMininput {
  min-width: 150px;
}
.etiquetas-section {
  background: white;
}
</style>
