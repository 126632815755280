<template>
  <div class="btt-layout" :class="colorClass">

    <v-btn v-if="success" :loading="loading" :disabled="disabled" v-bind="bind" text class="cotizador-btn-success" v-on="on">
    <slot> Button </slot>
    </v-btn>
    <v-btn v-else :loading="loading" :disabled="disabled" v-bind="bind" text class="cotizador-btn " :class="{animated}" v-on="on">
    <slot> Button </slot>
    </v-btn>
  </div>
</template>

<script>
export default {
  name: "CotizadorBtn",
  components: {},
  filters: {},
  props: {
    success: {
      type: Boolean,
      default: false,
    },
    bind: {
      type: Object,
      default: () => (null),
    },
    on: {
      type: Object,
      default: () => (null),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    animated: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    }
  },
  data: () => ({}),
  computed: {
    colorClass: function () {
      return "btt-default";
    },
  },
  mounted: function () {
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.btt-layout {
  .cotizador-btn {
    border: 1px solid rgba(0, 0, 0, 0.3);
    border-radius: 25px;
    cursor: pointer;
    pointer-events: all;
    background: #f9f9f9;
    color: black;
  }
}



.cotizador-btn-success {
  border: 1px solid var(--v-colorBtn-base);
  background-color: var(--v-colorBtn-base);
  border-radius: 25px;

  color: var(--v-colorTextBtn-base);
}

.cotizador-btn-success, .cotizador-btn
{
  &.animated {
      animation: blink-primary 2s linear infinite;
    }

    &.v-btn.v-btn--disabled {
      cursor: not-allowed;
      pointer-events: none;
      background: #f9f9f9;
      color: rgba(125,125,125, 0.7) !important;

      &::v-deep i.v-icon.v-icon--right.mdi.icon-cotizador-btn.v-icon--disabled {
        color: inherit !important;
      }
    }

    &:not(.v-btn--disabled):hover {
      // background: var(--v-colorBtn-lighten5);
      // background: rgba(var(--v-colorBtn-base), 0.7);
      background: color-mix(in srgb, var(--v-colorBtn-base) 50%, #f9f9f9);
    }
}

@keyframes blink-primary {
  0%, 100% {background-color:white;}
  80% {background-color: var(--v-colorBtn-base);}
}
</style>
