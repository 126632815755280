import i18n from '@/i18n.js';

export default {
    isDisableCustomPrices: () => process.env.VUE_APP_DISABLE_CUSTOM_PRICE === 'true',
    comercialLoginAvalible() {
        if (typeof process.env.VUE_APP_COMERCIAL_LOGIN === "boolean") {
            return process.env.VUE_APP_COMERCIAL_LOGIN;
        }

        if (typeof process.env.VUE_APP_COMERCIAL_LOGIN === "string") {
            return process.env.VUE_APP_COMERCIAL_LOGIN.toLowerCase() === "true";
        }

        return false;
    },
    clientCartAvalible(state, getters) {
        if (getters.isYouReprocesos) return false;
        if (typeof process.env.VUE_APP_CLIENT_CART === "boolean") {
            return process.env.VUE_APP_CLIENT_CART;
        }

        if (typeof process.env.VUE_APP_CLIENT_CART === "string") {
            return process.env.VUE_APP_CLIENT_CART.toLowerCase() === "true";
        }

        return false;
    },
    isPreciosProveedoresActive: () => process.env.VUE_APP_PRECIOS_PROVEEDORES === 'true',
    defaultPdfConfig: (state, getters, rootState) => {
        let defaultConfig = {
            dialog: false,
            colorSeleccionado: rootState.carrito.carrito.colorCode,
            porcentajeProductos: 0.0,
            porcentajeReprocesos: 0.0,
            costeExtra: 0.0,
            Observaciones: "",
            Empresa: "",
            correoContacto: "",
            telefono: "",
            calle: "",
            cp: "",
            poblacion: "",
            ciudad: "",
            pais: "",
            customImg: null,
            logo: (rootState.modelo.modelInfo.originalShop == "STM" || rootState.modelo.modelInfo.originalShop == "STA")
                ? "https://static.gorfactory.es/b2b/Calculadora/logo_stamina.png"
                : "https://static.gorfactory.es/b2b/Calculadora/logo_roly.png"
        };

        if (getters.isYouReprocesos) {
            defaultConfig.Observaciones = state.youReprocesosConfig?.observaciones ?? "";
            defaultConfig.correoContacto = state.youReprocesosConfig?.contactEmail ?? "";
            defaultConfig.telefono = state.youReprocesosConfig?.contactPhone ?? "";

            defaultConfig.calle = state.youReprocesosConfig?.addressStreet ?? "";
            defaultConfig.cp = state.youReprocesosConfig?.addressCp ?? "";
            defaultConfig.poblacion = state.youReprocesosConfig?.addressState ?? "";
            defaultConfig.ciudad = state.youReprocesosConfig?.addressCity ?? "";
            defaultConfig.pais = state.youReprocesosConfig?.addressCountry ?? "";

            defaultConfig.customImg = state.youReprocesosConfig?.logoPdfUrl || state.youReprocesosConfig?.logourl || null;

            defaultConfig.CustomLogo = state.youReprocesosConfig?.logoPdfUrl || state.youReprocesosConfig?.logourl || null;

            defaultConfig.CustomLogoPath = defaultConfig.CustomLogo;
        }

        return defaultConfig

    },
    tamanosImpresionDirecta() {
        return [
            {
                value: 1,
                Ancho: 400,
                Alto: 500,
                text: i18n.t(
                    "configuracion.ImpresionDirecta.Size1"
                ),
            },
            {
                value: 2,
                Ancho: 300,
                Alto: 400,
                text: i18n.t(
                    "configuracion.ImpresionDirecta.Size2"
                ),
            },
            {
                value: 3,
                Ancho: 250,
                Alto: 250,
                text: i18n.t(
                    "configuracion.ImpresionDirecta.Size3"
                ),
            },
            {
                value: 4,
                Ancho: 100,
                Alto: 100,
                text: i18n.t(
                    "configuracion.ImpresionDirecta.Size4"
                ),
            },
        ];
    },
    isProveedor: (state) => state.isProveedor && process.env.VUE_APP_PRECIOS_PROVEEDORES === 'true',
    allSizes: (state) => state.allSizes,
    allSizeByTecnica: (state) => (tecnica) => {

        if (tecnica == "Serigrafia" || tecnica == "DTF" || tecnica == 'SublimacionParcial') {
            let sizesSerigrafia = ["A3", "A4", "A5", "A6", "A7", "A8"];

            return sizesSerigrafia.reduce((obj, key) => {
                if (state.allSizes[key]) {
                    obj[key] = state.allSizes[key];
                }
                return obj;
            }, {});
        }
        return state.allSizes
    },
    allTintas: (state, getters, rootState, rootGetter) => {
        let tintas = [];

        const isCbModel = rootGetter["modelo/modelInfoFromCatalog"]?.code.startsWith("CB");

        if (isCbModel) {
            tintas = [
                { text: i18n.t("configuracion.Tintas.Tinta6"), value: 6 },
            ];
        }
        else if (rootGetter["modelo/isReprocesosStm"]) {
            return [
                {
                    text: i18n.t("configuracion.Tintas.Tinta5"),
                    value: 5
                },
                {
                    text: i18n.t("configuracion.Tintas.Tintas especiales STM"),
                    value: 1
                }
            ];
        }
        else if (!rootGetter["modelo/contieneElastano"]) {
            tintas = [
                {
                    text: i18n.t("configuracion.Tintas.Tinta1"),
                    value: 1,
                },
                {
                    text: i18n.t("configuracion.Tintas.Tinta2"),
                    value: 2,
                },
                { text: i18n.t("configuracion.Tintas.Tinta3"), value: 3 },
                {
                    text: i18n.t("configuracion.Tintas.Tinta5"),
                    value: 5,
                },
                { text: i18n.t("configuracion.Tintas.Tinta6"), value: 6 },
            ];
        }
        else {
            tintas = [
                {
                    text: i18n.t("configuracion.Tintas.Tinta2"),
                    value: 2,
                },
                { text: i18n.t("configuracion.Tintas.Tinta3"), value: 3 },
            ];
        }

        let forcedTinta = rootGetter['modelo/forzarTipoTinta']

        if (
            forcedTinta != null &&
            forcedTinta > 0
        ) {
            switch (forcedTinta) {
                case 1: // Elastano
                    forcedTinta = 2;
                    break;
                case 2: // Vinilo
                    forcedTinta = 6;
                    break;
                case 3: // Tinta al agua
                    forcedTinta = 3;
                    break;
                case 4: // Tinta de descarga
                    forcedTinta = 4;
                    break;
                case 5: // Tintas especiales (Oro, Plata, etc)
                    forcedTinta = 1;
                    break;
                default:
                    forcedTinta = undefined;
                    break;
            }
            let tinta = tintas.find((t) => t.value == forcedTinta);
            if (tinta != null) {
                tintas = [tinta];
            }
        }
        return tintas;
    },
    optionsNumerosDtf: (state) => (numerosDobles = false) => {
        return state.optionsNumerosDtf.filter(option => option.isDobleNumero == numerosDobles);
    },
    optionsNumerosTransfer: (state) => (numerosDobles = false) => {
        return state.optionsNumerosTransfer.filter(
            option => option.isDobleNumero === numerosDobles
        );
    },
    optionsNumbers: (state, getters) => (tecnica, numerosDobles = false) => {
        console.log("🚀 ~ numerosDobles:", numerosDobles)
        console.log("🚀 ~ tecnica:", tecnica, tecnica == "DTFNumeros" || tecnica.valueTecnica == "DTFNumeros")
        if (tecnica == "DTFNumeros" || tecnica.valueTecnica == "DTFNumeros") {
            return getters.optionsNumerosDtf(numerosDobles);
        }
        else if (tecnica == "TransferNumeros" || tecnica.valueTecnica == "TransferNumeros") {
            return getters.optionsNumerosTransfer(numerosDobles);
        }
        else {
            return []
        }
    },
    getMedidasByTamano: (state) => (tamano) => {
        return state.allSizes[tamano ?? "A3"]
    },
    actualLanguage: (state) => {
        return state.locale
    },
    actualLanguageRegion: (state) => {
        if (state.locale == "es")
            return "es-ES";
        else if (state.locale == "en")
            return "en-GB";
        else if (state.locale == "fr")
            return "fr-FR";
        else if (state.locale == "de")
            return "de-DE";
        else if (state.locale == "it")
            return "it-IT";
        else if (state.locale == "pt")
            return "pt-PT";
        else if (state.locale == "ru")
            return "ru-RU";
        else if (state.locale == "fi")
            return "fi-FI";
        else if (state.locale == "ro")
            return "ro-RO";
        else if (state.locale == "nl")
            return "nl-NL";
        else if (state.locale == "pl")
            return "pl-PL";
        else if (state.locale == "gr" || state.locale == "el")
            return "el-GR";
        else if (state.locale == "lv")
            return "lv-LV";
        else if (state.locale == "cz" || state.locale == "cs")
            return "cs-CZ";
        else if (state.locale == "sl" || state.locale == "si")
            return "si-SL";
        else
            return state.locale
    },
    getLocaleFromLanguageRegion: () => (languageRegion) => {
        switch (languageRegion) {
            case "es-ES":
                return "es";
            case "en-GB":
                return "en";
            case "fr-FR":
                return "fr";
            case "de-DE":
                return "de";
            case "it-IT":
                return "it";
            case "pt-PT":
                return "pt";
            case "ru-RU":
                return "ru";
            case "fi-FI":
                return "fi";
            case "ro-RO":
                return "ro";
            case "nl-NL":
                return "nl";
            case "pl-PL":
                return "pl";
            case "el-GR":
                return "gr";
            case "lv-LV":
                return "lv";
            case "cs-CZ":
                return "cz";
            case "si-SL":
                return "sl";
            default:
                return languageRegion;
        }
    },
    tecnicaMapExtraParam: () => {
        return new Map([
            ["Serigrafia", "Serigrafia"],
            ["TransferTintas", "Transfer"],
            ["Impresion", "ImpresionDirecta"],
            ["Manipulacion", "Manipulados"],
            ["SublimacionParcial", "Sublimacion"],
            ["Bordados", "Bordado"],
            ["SerigrafiaEtiquetas", "Etiquetas"],
            ["TransferNumeros", "Números"],
            ["ImpresionVinilo", "ImpresionVinilica"],
            ["SerigrafiaNonWoven", "SerigrafiaNonWoven"],
            ["TransferEspeciales", "TransferEspeciales"],
            ["Digital", "Digital"],
            ["Laser", "Laser"],
            ["Tampografia", "Tampografia"],
            ["Termografia", "Termograbado"],
            ["BordadoStm", "Bordados"],
            ["CuatricomiaStm", "Cuatricomia"],
            ["DTF", "DTF"],
            ["Planchado", "Planchado"],// No existe
            ["Doming", "Doming"],

            // Nuevos roly
            ["SublimacionTotal", "Sublimacion"],
            ["SublimacionTotalMedidas", "Sublimacion"],
            ["TransferEtiquetas", "Etiquetas"],
            ["TransferNumeroDigitos", "Números"],

            // Nuevos stamina
            ["SerigrafiaStm", "Serigrafia"],
            ["SerigrafiaStmSombreros", "Serigrafia"],
            ["SublimacionStm", "Sublimacion"],
            ["SublimacionStmSombreros", "Sublimacion"],
            ["SublimacionStmTazas", "Sublimacion"],
            ["TransferDigitalStm", "Transfer"],
            ["TransferStm", "Transfer"],
        ]);
    },
    getTecnicaMapExtraParam: (state, getter) => (tecnica) => {
        return getter.tecnicaMapExtraParam.get(tecnica) ?? tecnica;
    },
    translateTecnica: () => function (tecnica) {
        if (!tecnica) return;
        if (tecnica.includes('Sublimacion'))
            return this.$t('Tecnicas.SUBLIMACIÓN');
        else if (tecnica.includes('Serigrafia'))
            return this.$t("Tecnicas.SERIGRAFÍA");
        else if (tecnica.includes('TransferEspeciales'))
            return this.$t("Tecnicas.TRANSFER ESPECIALES");
        else if (tecnica.includes('TransferNumeros'))
            return this.$t("Tecnicas.NUMEROS-TRANSFER");
        else if (tecnica.includes('Transfer'))
            return this.$t("Tecnicas.TRANSFER");
        else if (tecnica.includes('ImpresionVinilo'))
            return this.$t("Tecnicas.IMPRESIÓN VINILO");
        else if (tecnica.includes('ImpresionDirecta') || tecnica.includes('Impresion'))
            return this.$t("Tecnicas.IMPRESIÓN DIRECTA");
        else if (tecnica.includes("Bordado"))
            return this.$t("Tecnicas.BORDADOS");
        else if (tecnica.includes("TransferNumeros"))
            return this.$t("Tecnicas.NÚMEROS");
        else if (tecnica.includes("SerigrafiaNonWoven"))
            return this.$t("Tecnicas.BOLSAS NON WOVEN");
        else if (tecnica.includes("Digital"))
            return this.$t("Tecnicas.DIGITAL")
        else if (tecnica.includes("Laser"))
            return this.$t("Tecnicas.LASER")
        else if (tecnica.includes('DTFNumeros'))
            return this.$t("Tecnicas.NUMEROS-DTF");
        else if (tecnica.includes("DTF"))
            return this.$t("Tecnicas.TRANSFER DIGITAL (DTF)");
        else if (tecnica.includes("Tampografia"))
            return this.$t("Tecnicas.TAMPOGRAFÍA")
        else if (tecnica.includes("Termografia"))
            return this.$t("Tecnicas.TERMOGRABADO");
        else if (tecnica.includes("DomingStm"))
            return this.$t("Tecnicas.DOMING");
        else if (tecnica.includes("CuatricomiaStm"))
            return this.$t("Tecnicas.CUATRICOMIA");
        else if (tecnica.includes("Planchado"))
            return this.$t("Tecnicas.PLANCHADO");
        else if (tecnica.includes("Manipulados") || tecnica.includes("Manipulacion"))
            return this.$t("Tecnicas.MANIPULADOS");
        else
            return "";

    },
    comercialInfo: (state) => state.comercial,
    comercialToken: (state) => state.comercial?.authToken,
    userList: (state) => state.userList ? Object.values(state.userList) : [],
    loggedClient: (state) => state.loggedClient,
    userOfClient: (state) => state.userOfClient,
    key: (state) => state.key,
    resetCalculadora: (state) => state.resetCalculadora,
    isModeIntranetActive: (state, getters) => getters.comercialLoginAvalible && getters.comercialToken && getters.comercialToken != "",
    colorSelected: state => state.colorSelected,

    activeComercial: (state, getters) => getters.loggedClient && getters.userOfClient && getters.comercialToken,
    activeClient: (state, getters) => !getters.activeComercial && getters.clientCartAvalible,
    isYouReprocesos: state => state.youReprocesosId != null,
    youReprocesosConfig: state => state.youReprocesosConfig,
    youReprocesosSession: state => state.youReprocesosSession,
    extraPrice: state => state.youReprocesosConfig?.extraPrice ?? 0,
    shippingCost: state => state.youReprocesosConfig?.shippingPrice ?? 0,
    company: state => state.company,
    isOk: state => state.company == "OKTXT",
    isStamina: state => state.company == "STA",
    initLoaderYouReprocesos: state => state.initLoaderYouReprocesos ?? false,
    sizeDigitalOptions: state => state.sizeDigitalOptions,
}