import { nextTick } from 'vue'

import { LoginComercial, SearchUserList, LoginClient, GetYouReprocesos } from "@/api/extranet"

import { FetchDtfNumerosSizes, FetchTransferNumerosSizes, FetchSizeDigitalOptions } from "@/api/reprocesos";

import i18n from '@/i18n.js';


export default {
    updateHeightFrame({ commit, state }, payload = undefined) {


        nextTick(() => {
            if (!payload || isNaN(payload)) {
                const productDialog = document.getElementById("productSelectorDialogBody")

                if (productDialog) {
                    const head = productDialog.getElementsByClassName('v-toolbar')?.[0]?.scrollHeight ?? 0;

                    const colorSelector = productDialog.getElementsByClassName('v-sheet')?.[0]?.scrollHeight ?? 0;

                    const alertSublimacionBlanco = productDialog.getElementsByClassName('alertSublimacionBlanco')?.[0]?.scrollHeight ?? 0;
                    console.log("🚀 ~ nextTick ~ alertSublimacionBlanco:", alertSublimacionBlanco)

                    const body = productDialog.getElementsByClassName('v-data-table')?.[0]?.scrollHeight ?? 0;

                    if (head && colorSelector && body)
                        payload = head + colorSelector + body + alertSublimacionBlanco + 80;
                }

                const searchDialog = document.getElementById("searchModelDialog")
                // Comprobamos si esta activo con la clase active-body-search
                if (searchDialog && searchDialog.classList.contains('active-body-search')) payload = 750
                if (!payload || isNaN(payload))
                    payload = document.getElementById("calculadoraContainer")?.scrollHeight ?? 0;
            }
            if (state.calculadoraHeight != payload) {
                commit("SET_HEIGHT_FRAME", payload);
                parent.postMessage({ type: 'resizeIframe', height: payload }, "*");
            }
        })
    },
    setProveedorCheck({ commit }, value) {
        commit("SET_IS_PROVEEDOR", value)
    },
    async setlocale({ commit }, key) {
        commit("SET_LOCALE", key);
    },
    async loginComercial({ commit }, payload) {
        let response = await LoginComercial({ payload })
        if (response.status == 200) {
            commit("SET_COMERCIAL", response.data);

            return response.data;
        }

        return null;
    },
    async tryLoginWithToken({ commit }) {
        let token = window.localStorage.getItem('authToken-calculator');
        if (!token) {
            return;
        }

        try {
            let response = await LoginComercial({ comercialToken: token })

            if (response.status === 200) {
                commit("SET_COMERCIAL", response.data);
                return true;
            }
        } catch (error) {
            // Avisamos y eliminamos el token
            console.error(error);
            window.localStorage.removeItem('authToken-calculator');
        }
    },

    async searchUserList({ commit, getters }, payload) {
        let search = payload.search;
        let showAllClient = payload.showAllClient;

        let response = await SearchUserList({
            search,
            showAllClient,
            comercialToken: getters.comercialToken
        })

        if (response.status === 200) {
            commit("SET_USER_LIST", response.data);
            return response.status === 200;
        }

        return false;
    },

    async loginClient({ commit, getters, dispatch }, payload) {
        let clientId = payload.clientId;

        let response = await LoginClient({
            clientId, comercialToken: getters.comercialToken
        })
        if (response.status === 200) {
            commit("CLIENT_LOGIN", response.data);
            dispatch("setUserOfClient", response.data.users[0]);
            return response.status === 200;
        }

        return false;
    },

    setUserOfClient({ commit }, payload) {
        commit("SET_USER_OF_CLIENT", payload);
    },

    async setKey({ commit, dispatch, getters }, key) {
        commit("SET_KEY", key);
        if (getters.clientCartAvalible)
            dispatch("carrito/fetchClientCart", null, { root: true })
        else if (getters.comercialLoginAvalible)
            dispatch("carrito/fetchComercialCart", null, { root: true })
    },

    logout({ commit }) {
        commit("SET_COMERCIAL", null);
        commit("CLIENT_LOGIN", null);
        commit("SET_USER_OF_CLIENT", null);
        commit("SET_KEY", null);
        if (localStorage.getItem("authToken-calculator"))
            window.localStorage.removeItem('authToken-calculator');
    },

    setResetCalculadora({ commit, dispatch }, payload) {
        commit("SET_RESET_CALCULADORA", payload);
        if (!payload)
            dispatch("modelo/restoreModel", null, { root: true })
    },

    setColor({ commit, dispatch }, payload) {
        commit("SET_COLOR", payload);

        // canvasJson.backgroundImage.src = new url
        dispatch("carrito/updateDesignColor", payload, { root: true })
    },

    // Intentamos pedir la configuración de YouReprocesos
    async setYouReprocesosSession({ commit, getters }, identificador) {
        if (!identificador) {
            console.error("Identificador no válido")
            return;
        }
        commit("SET_INIT_LOADER_YOUREPROCESOS", true)

        let result = await GetYouReprocesos(
            {
                id: identificador,
                company: getters.company
            }
        )

        commit("SET_INIT_LOADER_YOUREPROCESOS", false)

        if (result.status === 200) {
            commit("SET_YOU_REPROCESOS", result.data);

            return result.data;
        }
    },
    // Seleccionamos el valor de company
    setCompanyValue({ commit }, payload) {
        if (payload == "GOR" || payload == "OKTXT") {
            commit("SET_COMPANY_VALUE", payload);
        }
        else {
            console.warn("Debe especificar un valor válido para company (GOR o OKTXT)");
        }
    },
    async fetchOptionsNumerosDtf({ commit, state }) {
        if (state.optionsNumerosDtf.length > 0) return state.optionsNumerosDtf;
        return new Promise((resolve, reject) => {
            FetchDtfNumerosSizes()
                .then((response) => {
                    console.log("🚀 ~ .then ~ response:", response)
                    let options = response.data.map(option => ({
                        value: option.code,
                        Ancho: option.ancho,
                        Alto: option.alto,
                        isDobleNumero: option.doble,
                        label: i18n.t(`configuracion.NumerosDtf.${option.code}`),
                        minimum: option.minUnit
                    }));
                    console.log("🚀 ~ options ~ options:", options)

                    commit("SET_NUMEROS_DTF", options);

                    resolve(options);
                })
                .catch((error) => {
                    reject(error);
                });
        })
    },
    async fetchOptionsNumerosTransfer({ commit, state }) {
        if (state.optionsNumerosTransfer.length > 0) return state.optionsNumerosDtf;
        return new Promise((resolve, reject) => {
            FetchTransferNumerosSizes()
                .then((response) => {
                    let options = response.data.map(option => ({
                        Code: option.code,
                        Type: option.type,
                        isDobleNumero: option.isDouble,
                        Alto: option.height,
                        MaxColor: option.maxColor,
                        Label: i18n.t("configuracion.Numeros." + option.type)
                    }));

                    commit("SET_NUMEROS_TRASNFER", options);

                    resolve(options);
                })
                .catch((error) => {
                    reject(error);
                });
        })
    },
    async fetchSizeDigitalOptions({ commit, state, getters, rootGetters }, position) {

        if (state.sizeDigitalOptions?.[position]?.length > 0) return state.sizeDigitalOptions[position];

        return new Promise((resolve, reject) => {
            FetchSizeDigitalOptions({
                posicion: position,
                modelCode: rootGetters['modelo/modelCode'],
                reprocesosKey: getters.isYouReprocesos ? getters.youReprocesosSession : getters.key
            })
                .then((response) => {

                    commit("UPDATE_SIZE_DIGITAL_OPTIONS", { position, options: response.data });

                    resolve(response);
                }).catch((error) => {
                    reject(error);
                })

        })
    },
    cleanSizeDigital({ commit }) {
        commit("CLEAN_SIZE_DIGITAL");
    }
}