<template>
  <div class="pl-3">
    <v-dialog
      ref="designModal"
      v-model="dialog"
      max-width="600px"
      persistent
      transition="dialog-bottom-transition"
      class="dialog-zindex"
    >
      <template #activator="{}">
        <div @click="emitClick(true)">
          <CotizadorBtn
            v-if="!dark"
            :disabled="disabled || totalUnidades <= 0 || loadingCarrito"
          >
            {{ $t("presupuesto.diseñoTitle") }}
            <v-icon v-if="isSavedDesign" color="primary" class="ml-2">
              mdi-checkbox-marked-circle
            </v-icon>
          </CotizadorBtn>

          <v-tooltip
            :disabled="!isSavedDesign || !(isSavedDesign && idImg == 0)"
            top
          >
            <template #activator="{ on, attrs }">
              <CotizadorBtn
                v-bind="attrs"
                class="mt-2 mb-2"
                dark
                
                :disabled="disabled || totalUnidades <= 0 || loadingCarrito"
                v-on="on"
              >
                {{
                  isSavedDesign
                    ? $t("presupuesto.editCanvasTitle")
                    : $t("presupuesto.diseñoTitle")
                }}
                <v-icon
                  v-if="isSavedDesign"
                  :color="idImg > 0 ? 'primary' : 'warning'"
                  class="ml-1"
                >
                  mdi-checkbox-marked-circle
                </v-icon>
              </CotizadorBtn>
            </template>
            <span>{{ $t("presupuesto.designWithoutImg") }}</span>
          </v-tooltip>
        </div>
      </template>
      <v-card>
        <v-card-text>
          <v-col class="disenoBody">
            <div class="title1">
              <v-tabs v-model="tabs">
                <v-tab>
                  {{ $t("presupuesto.diseñoTitle") }}
                </v-tab>
                <v-tab :disabled="!actualDesign">
                  {{ $t("presupuesto.editCanvasTitle") }}
                </v-tab>
              </v-tabs>
            </div>
            <!-- <v-divider class="mt-1"></v-divider> -->
            <v-tabs-items v-model="tabs">
              <v-tab-item>
                <!-- Añadir diseño -->
                <v-row class="mt-1">
                  <v-col>
                    <div class="subtitle">
                      {{ $t("presupuesto.diseñoSubtitle") }}
                    </div>
                    <div class="infoDesign mt-2">
                      {{ $t("presupuesto.diseñoHelper") }}
                    </div>
                    <div>
                      <v-btn
                        :loading="isSelecting || loadingCarrito"
                        class="mt-3 fileBtn"
                        large
                        @click="handleFileImport"
                      >
                        <v-icon color="primary" class="mr-3">
                          mdi-plus-circle
                        </v-icon>
                        {{ $t("presupuesto.diseñoUpload") }}
                      </v-btn>
                      <input
                        ref="uploader"
                        class="d-none"
                        type="file"
                        accept="image/*,application/pdf"
                        @change="selectFile"
                      />
                    </div>
                  </v-col>
                  <v-col v-if="actualDesign" class="imgContainer">
                    <v-img
                      :key="idImg"
                      class="centerImg"
                      :src="actualDesign"
                      width="100%"
                      contain
                    ></v-img>
                    <div class="centerContainer">
                      <v-btn @click="resetImg">
                        {{ $t("configuracion.ReprocesosGenerales.Eliminar") }}
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                    </div>
                  </v-col>
                </v-row>
                <v-row class="mt-0">
                  <v-col>
                    <h4>
                      {{ $t("presupuesto.diseñoAlternative") }}
                    </h4>
                  </v-col>
                </v-row>
                <v-row class="mt-6">
                  <v-col>
                    <h3>{{ $t("presupuesto.Observaciones") }}</h3>
                    <v-textarea
                      v-model="observaciones"
                      class="mt-3"
                      filled
                      dense
                    />
                  </v-col>
                </v-row>
              </v-tab-item>
              <v-tab-item eager>
                <!-- Editar diseño -->
                <v-card :loading="loading">
                  <template>
                    <v-row class="mt-2">
                      <v-col class="d-flex align-center">
                        <ColorBall
                          v-for="c in sortColorAvalibleAndWithQuantity"
                          :key="c.code"
                          :hexcode="c.url ? c.url : c.hexcode"
                          :quantity="c.quantity"
                          :selected="colorSelected == c.code"
                          :label="c.code"
                          @changeColor="selectColor(c.code)"
                        ></ColorBall>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <canvas
                          id="canvas-personalizador"
                          ref="canvas"
                          width="500"
                          height="500"
                        ></canvas>
                        <!-- Checkbox para mostrar el fondo especial -->
                        <v-checkbox
                          v-if="avaliblePreview"
                          v-model="useBackgroundPreview"
                          :label="$t('general.Previsualizar')"
                          class="mt-2"
                          hide-details
                        ></v-checkbox>
                      </v-col>
                    </v-row>
                  </template>
                </v-card>
              </v-tab-item>
            </v-tabs-items>
          </v-col>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" text @click="cancelDesign">
            {{ $t("general.Cancelar") }}
          </v-btn>
          <v-btn color="primary" text @click="saveDesign">
            {{ $t("general.Guardar") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import CotizadorBtn from "@/components/basic/CotizadorBtn.vue";
import ColorBall from "@/components/basic/ColorBall.vue";
import { mapGetters, mapActions } from "vuex";
import { fabric, generateSvgFromJson, setBackgroundToSvg } from "@/utils/fabricUtils.js";
import { checkImageValid, noImgUrl } from "@/utils/imageUtils.js";
import {getTextColor} from "@/utils/colorUtils";               

export default {
  components: {
    CotizadorBtn,
    ColorBall
  },
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    zona: {
      type: Object,
      required: true
    },
    dark: Boolean,
    id: {
      type: Number,
      required: true
    },
    tecnica: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      dialog: false,
      isSelecting: false,
      actualDesign: null,
      originalFile: null,
      idImg: 0,
      observaciones: "",
      tabs: null,
      canvas: null,
      defaultConfig: {
        cornerColor: this.$vuetify.theme.currentTheme.primary,
        stroke: this.$vuetify.theme.currentTheme.primary,
        strokeWidth: 2,
      },
      useBackgroundPreview: false,
      loading: false
    };
  },
  computed: {
    ...mapGetters("carrito", [
      "totalUnidades",
      "savedDesign",
      "loadingCarrito",
      "sortColorAvalibleAndWithQuantity",
      "actualListDesign",
      "filteredListDesignInCart",
      "numDesignForEachPosition",
      "numDesignForEachPreviewRule"
    ]),
    ...mapGetters("modelo", ["newComputedImgUrl", "zonasMappedByPosicion"]),
    ...mapGetters("config", ["colorSelected", "isYouReprocesos"]),
    computedDark() {
      const primaryColor = this.$vuetify.theme.currentTheme.primary;
      const textColor = getTextColor(primaryColor);
      return (this.isYouReprocesos && textColor === "black") || (!this.isYouReprocesos && this.dark);
    },
    isSavedDesign() {
      // Si actualListDesign contiene el id actual es que esta guardado
      return (
        this.actualListDesign &&
        this.actualListDesign.findIndex(
          x => x.id == this.id && this.zona.Posicion == x.posicion 
        ) > -1
      );
    },
    avaliblePreview() {
      if(this.zona.PreviewRule) {
         return this.isSavedDesign
          ? this.numDesignForEachPreviewRule[this.zona.PreviewRule] > 1
          : this.numDesignForEachPreviewRule[this.zona.PreviewRule] > 0;
      }
      return this.isSavedDesign
        ? this.numDesignForEachPosition[this.zona.Zona] > 1
        : this.numDesignForEachPosition[this.zona.Zona] > 0;
    }
  },
  watch: {
    tabs(val) {
      if (val == 1) {
        this.initializeNewCanvas();
      }
    },
    dialog(val) {
      if (val) {
        this.tryInitializeNewCanvasFromDesignList();

        this.$nextTick(() => {
          this.$refs.designModal.showScroll();
        });
      } else {
        this.useBackgroundPreview = false;
      }
    },
    totalUnidades(newVal, oldVal) {
      if (newVal == oldVal) return;

      if (newVal != 0) return;

      this.dialog = false;
      this.tabs = null;
      if (this.canvas) {
        this.canvas.dispose();
        this.canvas = null;
      }
      this.resetImg();
    },
    modelo(newVal, oldVal) {
      if (newVal == oldVal) return;

      this.dialog = false;
      this.tabs = null;
      if (this.canvas) {
        this.canvas.dispose();
        this.canvas = null;
      }
      this.resetImg();
    },
    zona(newVal, oldVal) {
      if (newVal == oldVal) return;

      this.dialog = false;
      this.tabs = null;
      this.useBackgroundPreview = false;
      if (this.canvas) {
        this.canvas.dispose();
        this.canvas = null;
      }
      this.tryInitializeNewCanvasFromDesignList();
    },
    async useBackgroundPreview(newVal, oldVal) {
      if (newVal == oldVal) return;

      // Si no hay canvas salimos
      if (!this.canvas) return;

      if (!this.avaliblePreview) return;

      if (newVal) {
        await this.trySetPreviewAsBackground();
      } else {
        await this.setBackgroundImg();
        this.canvas.renderAll();
      }
    }
  },
  methods: {
    ...mapActions("carrito", [
      "saveZoneCustomDesign",
      "deleteZoneCustomDesign"
    ]),
    ...mapActions("config", ["setColor"]),
    async initializeNewCanvas(design) {
      if (!this.$refs.canvas) {
        console.error("No se ha podido inicializar el canvas");
        return;
      }

      if (this.totalUnidades <= 0) {
        console.error("Debe haber al menos una unidad para poder personalizar");
        return;
      }

      if (this.canvas) {
        console.warn("Ya existe un canvas");
        return;
      }

      if (!this.canvas) {
        this.canvas = new fabric.Canvas(this.$refs.canvas);
        this.canvas.selection = false;
        this.canvas.uniScaleTransform = true;
      }

      if (design) {
        await new Promise(resolve => {
          this.canvas.loadFromJSON(design.canvasJson, () => {
            this.canvas.renderAll.bind(this.canvas);

            this.canvas.forEachObject(obj => {
              obj.set(this.defaultConfig);
            });

            this.canvas.renderAll();
            resolve();
          });
        });
      } else {
        var self = this;

        // this.selectedColor = this.sortColorAvalibleAndWithQuantity[0]?.code;

        await this.setBackgroundImg();

        await new Promise((resolve, reject) => {
          fabric.Image.fromURL(this.actualDesign, function (savedDesignImage) {
            // Si esta imagen ya se encuentra en el canvas evitamos que se añade otra vez
            if (
              self.canvas
                .getObjects()
                .findIndex(x => x.src == savedDesignImage.src) > -1
            ) {
              reject("Image already exists in canvas");
            } else {
                savedDesignImage.scaleToWidth(150);
                const resultHeight = 150 * (savedDesignImage.height / savedDesignImage.width);
                let config = {
                  ...self.defaultConfig,
                  left: self.canvas.width / 2 - 150 / 2,
                  top: self.canvas.height / 2 - resultHeight / 2
                };
                savedDesignImage.set(config);
              self.canvas.add(savedDesignImage);
              self.canvas.renderAll();
              resolve();
            }
          });
        });
      }

      if (this.avaliblePreview) this.useBackgroundPreview = true;
    },
    async setBackgroundImg(image = null) {
      this.loading = true;

      try {
        let defaultImage = noImgUrl;

        if (image) {
          if (image.startsWith("http")) {
            defaultImage = image;
          } else {
            const { objects, options } = await new Promise(
              (resolve, reject) => {
                fabric.loadSVGFromString(image, (objects, options) => {
                  if (objects) {
                    resolve({ objects, options });
                  } else {
                    reject("Error loading SVG");
                  }
                });
              }
            );

            const obj = fabric.util.groupSVGElements(objects, options);
            obj.scaleToWidth(500);
            obj.set(this.defaultConfig);
            this.canvas.setBackgroundImage(
              obj,
              this.canvas.renderAll.bind(this.canvas)
            );
            return;
          }
        } else {
          if (this.zona) {
            defaultImage = this.newComputedImgUrl(this.zona, this.colorSelected);
          }
        }

        const defaultImageObj = await new Promise((resolve, reject) => {
          fabric.Image.fromURL(defaultImage, defaultImageObj => {
            if (defaultImageObj) {
              defaultImageObj.scaleToWidth(500);
              resolve(defaultImageObj);
            } else {
              reject("Error loading default image");
            }
          });
        });

        this.canvas.setBackgroundImage(
          defaultImageObj,
          this.canvas.renderAll.bind(this.canvas)
        );
      } finally {
        this.loading = false;
      }
    },

    async selectColor(color) {
      this.setColor(color);
      if (this.useBackgroundPreview && this.canvas) {
        await this.trySetPreviewAsBackground();
      } else {
        await this.setBackgroundImg();
        this.canvas.renderAll();
      }
    },
    emitClick(payload) {
      if (this.disabled || this.totalUnidades <= 0 || this.loadingCarrito)
        return;
      this.dialog = payload;
      parent.postMessage({ type: "scrollTop" }, "*");
      this.$emit("click", payload);
    },
    handleFileImport() {
      this.isSelecting = true;

      window.addEventListener(
        "focus",
        () => {
          this.isSelecting = false;
        },
        { once: true }
      );

      this.$refs.uploader.value = null;
      // Trigger click on the FileInput
      this.$refs.uploader.click();
    },
    selectFile(event) {
      const file = event.target.files[0];

      if (file) {
        if (file.size > 15000000) {
          alert(this.$t("alert.alertSizeBiggerThan15Mb"));
        } else {
          var reader = new FileReader();

          reader.addEventListener(
            "loadend",
            async () => {
              let base64 = reader.result;
              this.originalFile = reader.result;
              // Comprobamos si es un pdf
              if (file.type == "application/pdf") {
                // Importamos la librería
                const pdfjsLib = await import('pdfjs-dist/webpack');

                // Preparamos el base64
                base64 = atob(base64.replace("data:application/pdf;base64,", ""));

                // Cargamos el documento PDF
                const loadingTask = pdfjsLib.getDocument({ data: base64 });
                const pdf = await loadingTask.promise;

                // Renderizamos la primera página
                const page = await pdf.getPage(1);
                const scale = 1.5;
                const viewport = page.getViewport({ scale: scale });

                // Preparamos el canvas y su contexto
                const canvas = document.createElement("canvas");
                const context = canvas.getContext("2d");
                canvas.height = viewport.height;
                canvas.width = viewport.width;

                // Renderizamos la página en el canvas
                const renderContext = {
                  canvasContext: context,
                  viewport: viewport
                };
                await page.render(renderContext).promise;

                // Convertimos el canvas a una imagen PNG en base64
                base64 = canvas.toDataURL("image/png");
              }

              // this.$set(this.misPrecios, "CustomLogo", file);
              this.actualDesign = base64;
              this.idImg++;
              if (this.canvas) {
                this.canvas.dispose();
                this.canvas = null;
              }

              this.initializeNewCanvas();
              this.tabs = 1;
            },
            { once: true }
          );

          reader.readAsDataURL(file);
        }
      }
    },
    resetImg() {
      this.actualDesign = null;
      this.idImg = 0;
      this.observaciones = "";
      this.originalFile = null;
    },
    async saveDesign() {
      if (this.idImg > 0 || this.observaciones != "") {
        if (this.useBackgroundPreview && this.canvas)
          await this.setBackgroundImg();

        if (this.canvas) {
          this.canvas.forEachObject(obj => {
            obj.set({
              strokeWidth: 0,
            });
          });
          this.canvas.renderAll();
        }

        let informationActualZone = {
          id: this.id,
          tecnica: this.tecnica,
          zona: this.zona.Zona,
          posicion: this.zona.Posicion,
          design: this.idImg > 0 ? this.actualDesign : null,
          originalFile: this.originalFile,
          observaciones: this.observaciones,
          svg: this.canvas
            ? this.canvas.toSVG({
                suppressPreamble: true,
                width: "100%",
                height: "100%"
                // viewBox: null,
                // xmlns: null,
              })
            : // .replace(/width="[^"]+"/, '').replace(/height="[^"]+"/, '')
              null,
          canvasJson: this.canvas ? this.canvas.toJSON() : null,
          previewRule: this.zona.PreviewRule,
        };

        if (this.canvas) {
          informationActualZone.baseBackgroundImg = informationActualZone.canvasJson.backgroundImage.src;

          let imageWithoutFondo = this.newComputedImgUrl(this.zona, this.colorSelected, true);

          if (checkImageValid(imageWithoutFondo) && imageWithoutFondo != informationActualZone.baseBackgroundImg)
          {
            informationActualZone.svg = setBackgroundToSvg(informationActualZone.svg, imageWithoutFondo) ?? informationActualZone.svg;
          }
        }

        this.saveZoneCustomDesign(informationActualZone);
      } else if (this.isSavedDesign) {
        // Si no hay imagen y ya esta guardado lo eliminamos
        this.deleteZoneCustomDesign({
          id: this.id,
          posicion: this.zona.Posicion
        });
      }
      this.dialog = false;
      this.tabs = null;
      if (this.canvas) {
        this.canvas.dispose();
        this.canvas = null;
      }
    },
    cancelDesign() {
      this.dialog = false;
      this.tabs = null;
      if (this.canvas) {
        this.canvas.dispose();
        this.canvas = null;
      }
    },
    tryInitializeNewCanvasFromDesignList() {
      if (this.actualListDesign && this.actualListDesign.length > 0) {
        // Buscamos si alguno de los diseños coincide en Id
        let design = this.actualListDesign.find(
          x => x.id == this.id && this.zona.Posicion == x.posicion
        );

        if (design) {
          // Cargamos la imagen design en actualDesign y aumentamos el indice
          this.originalFile = design.originalFile;
          this.actualDesign = design.design;
          if (this.actualDesign) this.idImg++;

          // Cargamos las observaciones
          this.observaciones = design.observaciones;

          // Cargamos el canvas
          if (this.actualDesign) this.initializeNewCanvas(design);
        } else {
          this.resetImg();
        }
      }
    },
    async trySetPreviewAsBackground() {
      // De la lista de filtrados nos quedamos únicamente con los que tienen la misma posicion o tiene el mismo PreviewRule
      let filteredList = this.filteredListDesignInCart.filter(
        x =>  x.id != this.id && (this.zona.Posicion == x.posicion || (this.zona.PreviewRule && this.zona.PreviewRule == x.previewRule))
      );

      // Si no hay ninguno salimos
      if (filteredList.length == 0) {
        await this.setBackgroundImg();
        this.canvas.renderAll();
        return;
      }

      let canvasJson = filteredList.reduce(
        (acc, curr) => {
          acc.objects = acc.objects.concat(curr.canvasJson.objects);
          return acc;
        },
        { ...filteredList[0].canvasJson }
      );

      await generateSvgFromJson(canvasJson).then(svg => {
        this.setBackgroundImg(svg);
        this.canvas.renderAll();
      });
    }
  }
};
</script>

<style lang="scss">
$body-font-family: "Cairo";
$title-font: "Outfit";

.disenoBody {
  line-height: 15px;
  letter-spacing: 0px;
  .subtitle {
    font-size: 14px;
  }

  .infoDesign {
    font-size: 12px;
  }

  .imgContainer {
    max-width: 240px;
  }

  .v-btn {
    width: 100%;
  }

  .fileBtn {
    width: 100%;
    height: 100px !important;
    font-family: $title-font, sans-serif;
    font-size: 17px;
    line-height: 40px;
    font-weight: 500;
    text-transform: none !important;
    letter-spacing: 0 !important;
  }
}

#canvas-personalizador {
  border: 1px solid black;
}
</style>
