var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-dialog',{staticClass:"dialog-zindex",attrs:{"fullscreen":!_vm.isYouReprocesos,"hide-overlay":!_vm.isYouReprocesos,"transition":"dialog-bottom-transition","content-class":(_vm.isModeIntranetActive ? 'margin-top-max' : 'mt-10') + (_vm.isYouReprocesos ? ' productModal youReprocesosProductModal' : '')},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [(_vm.CotizadorBtn)?_c('CotizadorBtn',{attrs:{"bind":attrs,"on":on}},[_vm._v(" "+_vm._s(_vm.$t("presupuesto.guardar"))+" ")]):_c('v-btn',_vm._g(_vm._b({staticClass:"boton",attrs:{"color":"#000","dark":""}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$t("presupuesto.guardar"))+" ")])]}}]),model:{value:(_vm.dialogpdf),callback:function ($$v) {_vm.dialogpdf=$$v},expression:"dialogpdf"}},[_c('v-toolbar',{staticClass:"container2",attrs:{"max-height":"40px","height":"40px","dark":"","color":"primary"}},[(_vm.isModeIntranetActive || _vm.isYouReprocesos)?_c('CotizadorBtn',{attrs:{"on":{
            click: () => {
              _vm.close();
            }
          }}},[_vm._v(" "+_vm._s(_vm.$t("general.Atras"))),_c('v-icon',[_vm._v("mdi-step-backward")])],1):_vm._e(),(!_vm.isModeIntranetActive && !_vm.isYouReprocesos)?_c('div',{staticClass:"center",class:{ 'ml-15': _vm.isModeIntranetActive }},[_c('v-switch',{attrs:{"inset":"","dark":"","label":_vm.$t('presupuesto.MostrarMisGanancias')},model:{value:(_vm.switch1),callback:function ($$v) {_vm.switch1=$$v},expression:"switch1"}})],1):_vm._e(),_c('v-spacer'),_c('CotizadorBtn',{attrs:{"on":{
            click: () => {
              _vm.generateReport();
            }
          }}},[_c('v-fade-transition',[(_vm.cargandoPDF)?_c('v-overlay',{attrs:{"absolute":""}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"gray"}})],1):_vm._e()],1),_vm._v(" "+_vm._s(_vm.$t("presupuesto.imprimir"))+" ")],1)],1),_c('div',{staticClass:"maximaAnchura"},[_c('v-card',{staticClass:"maximaAnchura maxAltura"},[_c('div',{staticClass:"maxAltura"},[_c('previewpdf',{staticClass:"maxAltura",attrs:{"id":"GeneradorPDFPresupuesto","load":_vm.dialogpdf,"espdf":false,"title":_vm.title,"titulostabla1":_vm.$t('presupuesto.fichaReprocesos.Referencia'),"titulostabla2":_vm.$t('presupuesto.fichaReprocesos.Nombre'),"titulostabla3":_vm.$t('presupuesto.fichaReprocesos.Precio'),"titulostabla4":_vm.$t('presupuesto.fichaReprocesos.Cantidad'),"titulostabla5":_vm.$t('presupuesto.fichaReprocesos.Total'),"titulostabla6":_vm.$t('presupuesto.fichaReprocesos.MiPrecio'),"titulostabla7":_vm.$t('presupuesto.fichaReprocesos.Coste'),"mis-precios":_vm.MisPrecios,"mostrar-ganancias":_vm.switch1}})],1)])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }