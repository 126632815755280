import axios from "axios";
import _ from "lodash";
import { saveAs } from "file-saver";
import { checkImageValid } from "@/utils/imageUtils";
import { setBackgroundToSvg } from "@/utils/fabricUtils";
import i18n from "@/i18n"
import {
  CalcularPrecioCantidadesExactas,
  CalcularTecnica
} from "@/api/reprocesos";
import {
  GetCart,
  AddToCart,
  DeleteLineFromCart,
  DeleteCart,
  PrintReproceso,
  PrintIncompleteReproceso,
  FinishCart,
  PrintAllCart
} from "@/api/extranet";

const debounceCustomCalcularCantidadesExactas = _.debounce(
  ({ listReprocesos, divisa, isProveedor, language, key }, commit, state) => {
    commit("ADD_LOADING");
    if (!_.isEqual(listReprocesos, state.listReprocesos)) {
      commit("REMOVE_CANTIDADEXACTA");
      CalcularPrecioCantidadesExactas({
        payload: { listReprocesos },
        divisa,
        isProveedor,
        language,
        key
      })
        .then(response => {
          // response.data.tecnica = "CantidadExacta";
          let reproceso = {
            prices: response.data,
            params: {
              tecnica: "Cantidad Exacta",
              atributos: JSON.stringify({ listReprocesos })
            },
            isExtraCantidadExacta: true,
            tecnica: "CantidadExacta"
          };

          commit("ADD_REPROCESO", reproceso);
        })
        .catch(() => {
          return false;
        })
        .finally(() => {
          commit("REMOVE_LOADING");
        });
    }
  },
  500
);

export default {
  addLoadingCarrito({ commit }) {
    commit("ADD_LOADING");
  },
  removeLoadingCarrito({ commit }) {
    commit("REMOVE_LOADING");
  },


  deleteReproceso({ commit }, payload) {
    commit("ADD_LOADING");
    commit("DELETE_REPROCESO", payload);
    commit("REMOVE_LOADING");
  },

  deleteReprocesoById({ commit }, payload) {
    commit("ADD_LOADING");
    commit("DELETE_REPROCESO_BY_ID", payload);
    commit("REMOVE_LOADING");
  },

  restoreCarrito({ commit }, payload) {
    commit("ADD_LOADING");
    commit("RESTORE_CARRITO", payload);
    commit("REMOVE_LOADING");
  },

  async setColor({ commit }, payload) {
    commit("ADD_LOADING");
    commit("SET_COLOR", payload);
    commit("REMOVE_LOADING");
  },

  async updateNumeroReprocesos({ commit }, payload) {
    commit("ADD_LOADING");
    commit("UPDATE_N_REPROCESOS", payload);
    commit("REMOVE_LOADING");
  },
  async REMOVENumeroReprocesos({ commit }, payload) {
    commit("ADD_LOADING");
    commit("REMOVE_N_REPROCESOS", payload);
    commit("REMOVE_LOADING");
  },
  borrarManipulados({ commit }, payload) {
    commit("ADD_LOADING");
    commit("BORRAR_MANIPULADOS", payload);
    commit("REMOVE_LOADING");
  },

  async calcularPrecios(
    { commit, dispatch, state, rootGetters },
    parametros
  ) {
    console.log("----> CALCULO PRECIO");
    commit("ADD_LOADING");
    return new Promise((resolve, reject) => {
      parametros.divisa = rootGetters["modelo/currency"];
      parametros.language = rootGetters["config/actualLanguageRegion"];
      

      const actualTecnica = rootGetters["config/getTecnicaMapExtraParam"](parametros.tecnica)

      // Comprobación de que no tenga ningún color en el carrito baneado para alguna técnica
      let bannedColors = rootGetters["modelo/getBannedColorListByZoneAndTecnicas"](parametros.posicion, actualTecnica);

      if (bannedColors.length > 0) {
        let productoConColorBaneado = state.carrito.productos.find(producto => bannedColors.includes(producto.color));

        if (productoConColorBaneado) {
          dispatch(
            "modelo/addAlert",
            {
              code: "errorColorBanned",
              product: `${i18n.t("COLORS." + productoConColorBaneado.color)} (${productoConColorBaneado.color})`,
            },
            { root: true }
          );

          // Añadimos un reproceso "falso", para que lo detecte como error al no tener precios, y no deje continuar a menos que se modifique
          commit("ADD_REPROCESO", {
            prices: {
              articulos: null
            },
            tecnica: parametros.tecnica,
            lineas: [],
            total: 0,
            modelo: parametros.codigo,
            id: parametros.id,
            zona: parametros.zona,
            params: parametros
          });

          if (state.sobrecosteCantidadExacta > 0)
            dispatch("CalcularCantidadExacta");

          console.warn("Color baneado en la técnica", bannedColors);
          commit("REMOVE_LOADING");
          reject("errorColorBanned");
          return;
        }
      }

      CalcularTecnica({
        payload: parametros,
        key: rootGetters["config/isYouReprocesos"] ? rootGetters["config/youReprocesosSession"] : rootGetters["config/key"]
      })
        .then(response => {
          let reproceso = {
            prices: response.data,
            params: parametros
          };

          if (response.data.articulos.length == 0) {
            dispatch(
              "modelo/addAlert",
              {
                code: "errorResponseEmpty"
              },
              { root: true }
            );
          }

          commit("ADD_REPROCESO", reproceso);

          if (state.sobrecosteCantidadExacta > 0)
            dispatch("CalcularCantidadExacta");
          resolve(response);
        })
        .catch(error => {
          console.error(error);
          reject(error);
        })
        .finally(() => {
          commit("REMOVE_LOADING");
        });
    });

  },

  // Obsoleta
  async calcularPreciosTransferEspeciales(
    { commit, dispatch, state, rootGetters },
    parametros
  ) {
    commit("ADD_LOADING");
    let parametros2 = {
      codigoModelo: parametros.codigoModelo,
      calidad: parametros.calidad,
      colores: parametros.colores,
      prendas: parametros.prendas,
      idTamano: parametros.idTamano,
      antimigracion: parametros.antimigracion,
      ignifugo: parametros.ignifugo,
      feston: parametros.feston,
      esRepeticion: parametros.esRepeticion,
      precioProveedor: parametros.precioProveedor,
      divisa: rootGetters["modelo/currency"],
      language: rootGetters["config/actualLanguageRegion"]
    };
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${process.env.VUE_APP_WEB_MARCADO}/Reprocesos/getSAPTransferEspeciales`,
          {
            params: parametros2
          }
        )
        .then(response => {
          let reproceso = {
            tecnica: parametros.tecnica,
            zona: parametros.zona.Zona,
            modelo: parametros.codigoModelo,
            productos: [],
            lineas: [],
            atributos: null,
            data: response.data,
            sap: response.data.sap,
            info: response.data.info,
            total: 0,
            params: {
              ...parametros,
              type: "TransferEspeciales"
            }
          };

          commit("ADD_REPROCESO", reproceso);
          if (state.sobrecosteCantidadExacta > 0)
            dispatch("CalcularCantidadExacta");
          resolve(response);
        })
        .catch(error => {
          reject(error);
        })
        .finally(() => {
          commit("REMOVE_LOADING");
        });
    });
  },

  async fetchComercialCart({ commit, rootGetters }) {
    if (!rootGetters["config/loggedClient"]) return;
    commit("ADD_LOADING");
    return new Promise((resolve, reject) => {
      GetCart({
        comercialToken: rootGetters["config/comercialToken"],
        reprocesosKey: rootGetters["config/key"],
        type: "COMERCIAL"
      })
        .then(response => {
          commit("SET_COMERCIAL_CART", response.data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        })
        .finally(() => {
          commit("REMOVE_LOADING");
        });
    });
  },

  async fetchClientCart({ commit, rootGetters }) {
    commit("ADD_LOADING");
    return new Promise((resolve, reject) => {
      GetCart({
        reprocesosKey: rootGetters["config/key"],
        type: "CLIENT"
      })
        .then(response => {
          commit("SET_CLIENT_CART", response.data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        })
        .finally(() => {
          commit("REMOVE_LOADING");
        });
    });
  },

  async addToClientCart({ commit }, pedido) {
    commit("ADD_LOADING");

    let orderBody = new FormData();
    orderBody.append("NewDesignDataForm", pedido.body.NewDesignDataForm);
    orderBody.append("OrderForm", JSON.stringify(pedido.body.order));
    if (pedido.BocetosRepetidos) {
      pedido.BocetosRepetidos.forEach(boceto => {
        orderBody.append(`Bocetos[${boceto.id}]`, boceto.value);
      });
    }
    return new Promise((resolve, reject) => {
      AddToCart({
        type: "CLIENT",
        payload: orderBody,
        reprocesosKey: pedido.key
      })
        .then(response => {
          commit("CONFIRM_CARRITO", pedido);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        })
        .finally(() => {
          commit("REMOVE_LOADING");
        });
    });
  },

  async deleteLineClientCart({ commit, rootGetters }, reprocesoId) {
    commit("ADD_LOADING");
    return new Promise((resolve, reject) => {
      DeleteLineFromCart({
        lineId: reprocesoId,
        reprocesosKey: rootGetters["config/key"],
        type: "CLIENT"
      })
        .then(response => {
          commit("SET_CLIENT_CART", response.data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        })
        .finally(() => {
          commit("REMOVE_LOADING");
        });
    });
  },

  async deleteCompleteClientCart({ commit, rootGetters }) {
    commit("ADD_LOADING");
    return new Promise((resolve, reject) => {
      DeleteCart({
        reprocesosKey: rootGetters["config/key"],
        type: "CLIENT"
      })
        .then(response => {
          commit("SET_CLIENT_CART", []);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        })
        .finally(() => {
          commit("REMOVE_LOADING");
        });
    });
  },

  async confirmClientCart({ commit, rootGetters }, payload) {
    commit("ADD_LOADING");
    return new Promise((resolve, reject) => {
      FinishCart({
        reprocesosKey: rootGetters["config/key"],
        payload,
        type: "CLIENT"
      })
        .then(response => {
          commit("SET_LAST_CART", rootGetters["carrito/clientCart"]);
          commit("SET_CLIENT_CART", []);
          commit("SET_FINISH_CART", response.data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        })
        .finally(() => {
          commit("REMOVE_LOADING");
        });
    });
  },

  async printReprocesoClient({ commit, rootGetters }, reprocesoId) {
    commit("ADD_LOADING");
    return new Promise((resolve, reject) => {
      PrintReproceso({
        lineId: reprocesoId,
        reprocesosKey: rootGetters["config/key"],
        lang: rootGetters["config/actualLanguageRegion"]
      })
        .then(response => {
          saveAs(response.data, `Reproceso-${reprocesoId}.pdf`);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        })
        .finally(() => {
          commit("REMOVE_LOADING");
        });
    });
  },

  async confirmComercialCart({ commit, rootGetters }, payload) {
    commit("ADD_LOADING");
    return new Promise((resolve, reject) => {
      FinishCart({
        reprocesosKey: rootGetters["config/key"],
        comercialToken: rootGetters["config/comercialToken"],
        payload,
        type: "COMERCIAL"
      })
        .then(response => {
          commit(
            "SET_LAST_CART",
            rootGetters["carrito/comercialCart"]
          );
          commit("SET_COMERCIAL_CART", []);
          commit("SET_FINISH_CART", response.data);

          resolve(response);
        })
        .catch(error => {
          reject(error);
        })
        .finally(() => {
          commit("REMOVE_LOADING");
        });
    });
  },

  async deleteLineComercialCart({ commit, rootGetters }, reprocesoId) {
    commit("ADD_LOADING");
    return new Promise((resolve, reject) => {
      DeleteLineFromCart({
        lineId: reprocesoId,
        reprocesosKey: rootGetters["config/key"],
        comercialToken: rootGetters["config/comercialToken"],
        type: "COMERCIAL"
      })
        .then(response => {
          commit("SET_COMERCIAL_CART", response.data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        })
        .finally(() => {
          commit("REMOVE_LOADING");
        });
    });
  },

  async deleteCompleteComercialCart({ commit, rootGetters }) {
    commit("ADD_LOADING");
    return new Promise((resolve, reject) => {
      DeleteCart({
        comercialToken: rootGetters["config/comercialToken"],
        reprocesosKey: rootGetters["config/key"],
        type: "COMERCIAL"
      })
        .then(response => {
          commit("SET_COMERCIAL_CART", []);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        })
        .finally(() => {
          commit("REMOVE_LOADING");
        });
    });
  },

  async printReproceso({ commit, rootGetters }, reprocesoId) {
    commit("ADD_LOADING");
    return new Promise((resolve, reject) => {
      PrintReproceso({
        lineId: reprocesoId,
        comercialToken: rootGetters["config/comercialToken"]
      })
        .then(response => {
          // commit("SET_COMERCIAL_CART", response.data);
          saveAs(response.data, `Reproceso-${reprocesoId}.pdf`);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        })
        .finally(() => {
          commit("REMOVE_LOADING");
        });
    });
  },

  async addProducto({ commit }, payload) {
    commit("ADD_LOADING");
    commit("ADD_PRODUCTO", payload);
    commit("UPDATE_TOTALES");
    commit("REMOVE_LOADING");
  },
  async deleteProducto({ commit }, producto) {
    commit("ADD_LOADING");
    commit("DELETE_PRODUCTO", producto);
    commit("UPDATE_TOTALES");
    commit("REMOVE_LOADING");
  },
  async deleteAllProducts({ commit, state }) {
    commit("ADD_LOADING");
    state.carrito.productos.forEach(producto => {
      commit("DELETE_PRODUCTO", producto);
    });
    commit("UPDATE_TOTALES");
    commit("REMOVE_LOADING");
  },

  async confirmPedido({ commit, rootGetters, dispatch }, pedido) {
    // Añadimos los bocetos anteriores, en caso de que tengamos alguna coincidencia de id
    let bocetosRepetidos = rootGetters["carrito/bocetosRepeticion"];
    if (bocetosRepetidos.length > 0) {
      pedido.BocetosRepetidos = bocetosRepetidos;
    }

    // Si estamos en una sesión de cliente
    if (
      rootGetters["config/clientCartAvalible"] &&
      !(
        rootGetters["config/comercialInfo"] &&
        rootGetters["config/comercialToken"]
      )
    )
      return dispatch("addToClientCart", pedido);

    let orderBody = new FormData();
    orderBody.append("NewDesignDataForm", pedido.body.NewDesignDataForm);
    orderBody.append("OrderForm", JSON.stringify(pedido.body.order));
    if (pedido.body.YouReprocesosInfo)
      orderBody.append("YouReprocesosInfoForm", JSON.stringify(pedido.body.YouReprocesosInfo));
    if (pedido.BocetosRepetidos) {
      pedido.BocetosRepetidos.forEach(boceto => {
        orderBody.append(`Bocetos[${boceto.id}]`, boceto.value);
      });
    }

    commit("ADD_LOADING");
    return new Promise((resolve, reject) => {
      AddToCart({
        type:
          rootGetters["config/comercialToken"] &&
            rootGetters["config/comercialInfo"]
            ? "COMERCIAL"
            : rootGetters["config/isYouReprocesos"]
              ? "YOUREPROCESOS"
              : "CLIENT",
        payload: orderBody,
        reprocesosKey: rootGetters["config/isYouReprocesos"] ? rootGetters["config/youReprocesosSession"] : pedido.key,
        comercialToken: rootGetters["config/comercialToken"]
      })
        .then(response => {
          commit("CONFIRM_CARRITO", pedido);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        })
        .finally(() => {
          commit("REMOVE_LOADING");
        });
    });
  },

  async uploadImage({ commit }, imagen) {
    commit("ADD_LOADING");
    const formData = new FormData();
    formData.append("imagenB64", imagen.imagen);
    formData.append("key", imagen.key);
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${process.env.VUE_APP_WEB_MARCADO}/Reprocesos/uploadDesign`,
          formData,
          {
            headers: {
              "Content-Type": "text/xml"
            }
          }
        )
        .then(response => {
          commit;
          resolve(response);
        })
        .catch(error => {
          reject(error);
        })
        .finally(() => {
          commit("REMOVE_LOADING");
        });
    });
  },

  async dowloadAllPresupuestosInCart({ commit, rootGetters }, payload) {
    commit("ADD_LOADING");

    return new Promise((resolve, reject) => {
      PrintAllCart({
        type:
          rootGetters["config/comercialToken"] &&
            rootGetters["config/comercialInfo"]
            ? "COMERCIAL"
            : rootGetters["config/isYouReprocesos"]
              ? "YOUREPROCESOS"
              : "CLIENT",
        reprocesosKey: rootGetters["config/isYouReprocesos"] ? rootGetters["config/youReprocesosSession"] : rootGetters["config/key"],
        lang: rootGetters["config/actualLanguageRegion"],
        comercialToken: rootGetters["config/comercialToken"],
        payload
      }).then(response => {
        const iso = new Date().toISOString();
        let formattedDate =
          iso.slice(2, 10).replace(/-/g, "") +
          "_" +
          iso.slice(11, 16).replace(/:/g, "");
        const fileName = `Reprocesos_cart_${formattedDate}.pdf`;
        saveAs(response.data, fileName);
        // commit("CONFIRM_PRESUPUESTO", pedido);
        resolve(response);
      })
        .catch(error => {
          reject(error);
          return false;
        })
        .finally(() => {
          commit("REMOVE_LOADING");
        });
    });
  },

  async presupuesto({ commit, rootGetters }, pedido) {
    commit("ADD_LOADING");
    return new Promise((resolve, reject) => {
      PrintIncompleteReproceso({
        payload: pedido,
        reprocesosKey: rootGetters["config/isYouReprocesos"] ? rootGetters["config/youReprocesosSession"] : rootGetters["config/key"],
        lang: rootGetters["config/actualLanguageRegion"]
      })
        .then(response => {
          const iso = new Date().toISOString();
          let formattedDate =
            iso.slice(2, 10).replace(/-/g, "") +
            "_" +
            iso.slice(11, 16).replace(/:/g, "");
          const fileName = `Reproceso_${formattedDate}.pdf`;
          saveAs(response.data, fileName);
          // commit("CONFIRM_PRESUPUESTO", pedido);
          resolve(response);
        })
        .catch(error => {
          reject(error);
          return false;
        })
        .finally(() => {
          commit("REMOVE_LOADING");
        });
    });
  },

  async CalcularCantidadExacta({ commit, state, rootGetters }) {
    let listReprocesos = state.carrito.reprocesos
      .filter(r => !r.isExtraCantidadExacta)
      .map(r => r.params);

    debounceCustomCalcularCantidadesExactas(
      {
        listReprocesos,
        divisa: rootGetters["modelo/currency"],
        isProveedor: rootGetters["config/isProveedor"],
        key: rootGetters["config/key"],
      },
      commit,
      state
    );
  },

  async EliminarCantidadExacta({ commit }) {
    commit("ADD_LOADING");
    return new Promise((resolve, reject) => {
      try {
        commit("REMOVE_CANTIDADEXACTA");
        resolve(true);
      } catch (error) {
        reject(error);
        return false;
      }
    }).finally(() => {
      commit("REMOVE_LOADING");
    });
  },


  saveZoneCustomDesign({ commit }, payload) {
    commit("ADD_LOADING");
    commit("SAVE_ZONE_CUSTOM_DESIGN", payload);
    commit("REMOVE_LOADING");
  },

  deleteZoneCustomDesign({ commit }, payload) {
    commit("ADD_LOADING");
    commit("DELETE_ZONE_CUSTOM_DESIGN", payload);
    commit("REMOVE_LOADING");
  },

  cleanAllDesign({ commit }) {
    commit("ADD_LOADING");
    commit("DELETE_ALL_ZONE_CUSTOM_DESIGN");
    commit("REMOVE_LOADING");
  },

  updateDesignColor({ commit, state, rootGetters }, colorCode) {
    commit("ADD_LOADING");

    let modifiedDesign = [...state.actualDesign];

    // canvasJson.backgroundImage.src = new url
    modifiedDesign.forEach(design => {
      // Calculamos la imagen
      let actualZonaImg = rootGetters["modelo/newComputedImgUrl"](
        {
          Zona: design.zona,
          Posicion: design.posicion
        },
        colorCode,
        true
      );

      let actualZonaImgWithSquare = rootGetters[
        "modelo/newComputedImgUrl"
      ](
        {
          Zona: design.zona,
          Posicion: design.posicion
        },
        colorCode
      );

      if (
        !checkImageValid(actualZonaImg) &&
        checkImageValid(actualZonaImgWithSquare)
      ) {
        actualZonaImg = actualZonaImgWithSquare;
      }

      if (actualZonaImg) {
        // Actualizamos el json
        design.canvasJson.backgroundImage.src = actualZonaImgWithSquare;
        // Tenemos que modificar el svg
        design.svg = setBackgroundToSvg(design.svg, actualZonaImg);
      }
    });

    if (modifiedDesign.length > 0)
      commit("UPDATE_DESIGN_COLOR", modifiedDesign);
    commit("REMOVE_LOADING");
  },
  addBocetoRepeticion({ commit }, payload) {
    commit("ADD_LOADING");
    commit("ADD_BOCETO_REPETICION", payload);
    commit("REMOVE_LOADING");
  },
  deleteBocetoRepeticion({ commit }, payload) {
    commit("ADD_LOADING");
    commit("DELETE_BOCETO_REPETICION", payload);
    commit("REMOVE_LOADING");
  },

  // Función para decidir entre dos funciones que ejecutar
  async useCorrectCartFunctionAsync(
    { commit, rootGetters, dispatch },
    payload
  ) {
    // Distintos dispatch a ejecutar
    const dispatchClient = payload.dispatchClient;
    const dispatchComercial = payload.dispatchComercial;

    // Distintos payloads a enviar
    const payloadClient = payload.payloadClient || payload.genericPayload || {};
    console.log("🚀 ~ payloadClient:", payloadClient)
    const payloadComercial = payload.payloadComercial || payload.genericPayload || {};
    console.log("🚀 ~ payloadComercial:", payloadComercial)

    let errorMessage = "Error al procesar la solicitud";
    commit("ADD_LOADING");
    return new Promise((resolve, reject) => {
      if (rootGetters["config/activeClient"]) {

        if (!dispatchClient) {
          commit("REMOVE_LOADING");
          reject(errorMessage + " Función de cliente no definida");
        }

        dispatch(dispatchClient, payloadClient)
          .then(resolve)
          .catch(reject)
          .finally(() => {
            commit("REMOVE_LOADING");
          });
      } else if (rootGetters["config/activeComercial"]) {

        if (!dispatchComercial) {
          commit("REMOVE_LOADING");
          reject(errorMessage + " Función de comercial no definida");
        }

        dispatch(dispatchComercial, payloadComercial)
          .then(resolve)
          .catch(reject)
          .finally(() => {
            commit("REMOVE_LOADING");
          });
      } else {
        commit("REMOVE_LOADING");
        reject(errorMessage + " No activo comercial ni cliente");
      }
    });
  },

  async fetchActualCart({ dispatch }) {
    return dispatch("useCorrectCartFunctionAsync", {
      dispatchClient: "fetchClientCart",
      dispatchComercial: "fetchComercialCart",
    });
  },

  async deleteActualCart({ dispatch }) {
    return dispatch("useCorrectCartFunctionAsync", {
      dispatchClient: "deleteCompleteClientCart",
      dispatchComercial: "deleteCompleteComercialCart",
    });
  },

  async confirmActualCart({ dispatch }, payload) {
    return dispatch("useCorrectCartFunctionAsync", {
      dispatchClient: "confirmClientCart",
      dispatchComercial: "confirmComercialCart",
      genericPayload: payload,
    });
  },

  // No la usamos ya que no hace una validación real
  async validateAddress({ commit, rootGetters }, address) {
    commit("ADD_LOADING");
    return new Promise((resolve, reject) => {
      axios
        .post(`${process.env.VUE_APP_EXTRANET_URL}/Client/Address/Validate`, address, { headers: { ReprocesosKey: rootGetters['config/key'] } })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        }).finally(() => {
          commit("REMOVE_LOADING");
        });
    });
  },

  async fetchAddress({ commit, rootGetters }) {
    commit("ADD_LOADING");
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.VUE_APP_EXTRANET_URL}/Client/Address/Shipping`, {
          params: {
            Size: 0
          },
          headers: {
            ReprocesosKey: rootGetters['config/key']
          },
        })
        .then((response) => {
          commit("SET_ADDRESS", response.data?.list ?? []);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        }).finally(() => {
          commit("REMOVE_LOADING");
        });
    });
  },
  updateParamsHeightAndWidthWithoutUpdatePrice({ commit }, payload) {
    commit("ADD_LOADING");
    commit("UPDATE_PARAMS_HEIGHT_AND_WIDTH_WITHOUT_UPDATE_PRICE", payload);
    commit("REMOVE_LOADING");
  }
};
