<template>
  <v-container fluid  class="px-0 pt-0  px-md-5">
    <alerta
              :dialog="alertActive"
              :titulo="alertError.title"
              :boton-cerrar="$t('confirm.Cerrar')"
              :cuerpo="alertError.body"
              :type-error="alertError"
              @cerrar="alertActive = false"
            ></alerta>
    <v-row>
      <template  v-if="!modelPrice">
          <v-col
            key="loading-model"
            class="d-flex justify-center align-center flex-column mt-12"
          >
            <v-progress-circular
              :rotate="360"
              :size="100"
              :width="15"
              indeterminate
              color="primary"
            ></v-progress-circular>
            <span class="mt-4">DEBE SELECCIONAR UN MODELO PARA COMENZAR</span>
          </v-col>
        
      </template>
      <template v-else>
        <v-col key="cotizador-main" class="scrollable-column" cols="12" md="5" lg="3">
          <v-container fluid class="px-0 px-md-5 left-column" >
            <v-row>
              <v-col class="d-flex d-md-block justify-center">
                <b class="model-title">
                  {{ $t(`MODELS.${modelCode}.NAME`) }}
                  <span class="slug">{{ modelCode }}</span>
                </b>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="d-flex justify-center pa-0 mt-3 mt-sm-0" cols="12" md="6">
                <productos @close="openSelectorZone" />
              </v-col>
              <v-col class="d-flex justify-center pa-0 mt-3 mt-sm-0" cols="12" md="6">
                <reprocesos-generales class="pl-md-3 w-100" />
              </v-col>
            </v-row>
            <v-row>
              <v-col class="d-flex  flex-column px-md-0 px-8">
                <span class="color-selector-title">{{ $t('general.Color') }}: <span class="color-selected">{{ hasColorSelected ?  $t(`COLORS.${color.code}`).toUpperCase() : '' }}</span></span>
                <div  class="color-list d-flex justify-start flex-wrap ml-2 mt-3">
                  <ColorBall v-for="c in sortColorAvalibleAndWithQuantity" :key="'colorBallMain-'+c.code" circle-class="" :hexcode="c.url ? c.url : c.hexcode" :quantity="c.quantity" tooltip :selected="hasColorSelected && color.code == c.code" :label="c.code" @changeColor="selectColor(c.code)">
                    <span><u>{{ $t(`COLORS.${c.code}`).toUpperCase() }}</u></span>
                    <div v-for="sizeQuantity in quantityByColorAndProduct[c.code]" :key="sizeQuantity.size">
                      <span>{{ $t(`SIZES.${sizeQuantity.size}`) }}: {{ sizeQuantity.quantity }}</span>
                    </div>
                </ColorBall>
              </div>
              </v-col>
            </v-row>
            <v-row class="scrollable-row ">
              <v-col class="mh-100 pa-md-0">
                <zonas-tabs :init-zones="initZones" @updateSelectedZone="updateZone" />
              </v-col>
            </v-row>
          </v-container>
        </v-col>
        <v-col class="pa-0" cols="12"  md="7" lg="6">
            <v-row  v-if="!hasValidInitState" key="loading">
                <v-col class="d-flex justify-center align-center flex-column mt-12">
                  <v-progress-circular
                    :rotate="360"
                    :size="100"
                    :width="15"
                    indeterminate
                    color="primary"
                  ></v-progress-circular>
                  <span v-if="totalUnidades == 0" class="mt-4">AÑADE AL MENOS UNO O VARIAS TALLAS</span>
                  <span v-else-if="!hasColorSelected" class="mt-4">DEBE SELECCIONAR UN UN COLOR PARA PREVISUALIZAR EL MODELO</span>
                </v-col>
              </v-row>
            <v-row v-else key="designer-3d">
              <v-col class="pa-0">
                 <design3DModal :active="hasValidInitState" :color="color" :zona="zona" />
              </v-col>
            </v-row>
        </v-col>
        <v-col class="mt-lg-0 mt-8" cols="12" lg="3">
          <v-container fluid class="px-0 px-md-5" >
            <v-row v-if="!isYouReprocesos">
              <v-col>
                 <cart-modal/>
              </v-col>
            </v-row>
            <v-row v-if="!isYouReprocesos">
               <v-col cols="12" md="6">
                <cotizador-btn disabled content-class="w-100">Cargar borrador</cotizador-btn>
              </v-col>
               <v-col cols="12" md="6">
                <cotizador-btn disabled content-class="w-100">Guardar borrador</cotizador-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="d-flex flex-column">
                <cotizacion id="carrito" @reload="reiniciar"/>
              </v-col>
            </v-row>
          </v-container>
        </v-col>
      </template>
    </v-row>
   
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import Productos from "@/components/cotizadorClientes/Productos.vue";
import ReprocesosGenerales from "@/components/tecnicas/ReprocesosGenerales.vue";
import Cotizacion from "@/components/cotizadorClientes/Cotizacion.vue";
import design3DModal from "@/components/modales/design3DModal.vue";
import cartModal from "@/components/modales/cartModal.vue";
import zonasTabs from "@/components/cotizadorClientes/zonasTabs.vue"
import alerta from "@/components/modales/alert.vue";

import ColorBall from "@/components/basic/ColorBall.vue";
import CotizadorBtn from "@/components/basic/CotizadorBtn.vue";


export default {
  components: {
    Productos,
    ReprocesosGenerales,
    Cotizacion,
    ColorBall,
    design3DModal,
    CotizadorBtn,
    cartModal,
    zonasTabs,
    alerta
  },
  data() {
    return {
      color: null,
      zona: null,
      cartModalDialog: false,
      alertActive: false,
      alertError: {},
      initZones: false
    }
  },
  computed: {
    ...mapGetters("modelo", ["modelPrice", "modelCode", "colorAvalible", 'actualAlert']),
    ...mapGetters("carrito", ['totalUnidades', 'sortColorAvalibleAndWithQuantity', "quantityByColorAndProduct"]),
    ...mapGetters("config", ['isYouReprocesos']),
    // Indica si se ha seleccionado un color o no
    hasColorSelected() {
      return this.color && this.color.code
    },
    // Comprobación si debemos cargar el modelo 3D
    hasValidInitState() {
      return !(this.totalUnidades == 0 || !this.hasColorSelected)
    }
  },
  watch: {
    actualAlert(newVal) {
       this.alertError = newVal;
      this.alertActive = newVal && (newVal.body || newVal.code) ? true : false;
    },
    alertActive: function (newVal, oldVal) {
      if (!newVal && newVal != oldVal) {
        this.clearAlert();
      }
    },
    sortColorAvalibleAndWithQuantity(newVal) {
      if (newVal.length > 0 && !this.color) {
        this.color = newVal[0];
        this.setColor(newVal[0].code);
      }
    },
    modelCode(newVal) {
      if (newVal) {
        this.color = null;
        this.zona = null;
        if(this.totalUnidades <= 0)
          this.initZones = false;
      }
    }
  },
  methods: {
    ...mapActions('config', ['setColor']),
    ...mapActions('modelo', ['clearAlert']),
    // Para reiniciar la calculadora
    reiniciar() {
      this.$emit("reload");
    },
    // Para seleccionar un nuevo color para previsualizar
    selectColor(colorCode)
    {
      let color = this.colorAvalible.find((c) => c.code === colorCode);
      this.color = color;
      this.setColor(colorCode);
    },
    // Método para actualizar la zona
    updateZone(zone) {
      console.log("🚀 ~ updateZone ~ zone:", zone)
      this.zona = zone;
    },
    openSelectorZone() {
      this.initZones = true;
    }
  }
};
</script>

<style lang="scss">


.model-title {
  font-size: 30px;
  font-weight: 600;
  color: #000;
  text-align: center;
  margin-bottom: 1rem;
  font-family: $heading-font-family;

  .slug {
    font-size: 19px;
    font-weight: 400
  }
}

.color-selector-title {
  font-size: 12px;
  font-weight: 400;
  .color-selected {
    font-weight: bold;
  }
}


// .left-column {
//   display: flex;
//   flex-direction: column;
//   height: calc(100vh - 110px);

//   .row {
//     flex: 0 1 auto;
//   }

//   .row.scrollable-row {
//     flex: 1;
//     overflow: hidden;
//   }
// }
</style>
