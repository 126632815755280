export default {
    SET_HEIGHT_FRAME(state, payload) {
        state.calculadoraHeight = payload;
    },
    SET_IS_PROVEEDOR(state, payload) {
        state.isProveedor = payload;
    },
    SET_LOCALE(state, payload) {
        state.locale = payload;
    },
    SET_COMERCIAL(state, payload) {
        state.comercial = payload;
        if (payload)
            window.localStorage.setItem('authToken-calculator', payload.authToken);
    },
    SET_USER_LIST(state, payload) {
        if (!payload) {
            state.userList = {};
            return;
        }
        payload.listClient.forEach(client => {
            const { clientId, clientName } = client;
            const label = `${clientId} - ${clientName}`;
            client.listUser.forEach(user => user.label = `${user.user} - ${user.name}`);
            state.userList[clientId] = { ...client, label };
        });

        // Forzamos el update del componente
        state.userList = { ...state.userList };
    },
    CLIENT_LOGIN(state, payload) {
        if (!payload) {
            state.loggedClient = null;
            return;
        }
        payload.users = payload.users.map(u => {
            return {
                ...u,
                label: `${u.user} - ${u.name}`
            }
        })
        state.loggedClient = payload;
    },
    SET_USER_OF_CLIENT(state, payload) {
        state.userOfClient = payload;
    },
    SET_KEY(state, payload) {
        state.key = payload;
    },
    SET_RESET_CALCULADORA(state, payload) {
        state.resetCalculadora = payload;
    },
    SET_COLOR(state, payload) {
        state.colorSelected = payload;
    },
    SET_COMPANY_VALUE(state, payload) {
        state.company = payload;
    },
    SET_YOU_REPROCESOS(state, payload) {
        state.youReprocesosId = payload.identificador;
        state.youReprocesosConfig = payload.configuration;
        state.youReprocesosSession = payload.session;
    },
    SET_INIT_LOADER_YOUREPROCESOS(state, payload) {
        state.initLoaderYouReprocesos = payload;
    }
}