<template>
    <v-container class="pb-2">
        <v-row class="mt-0">
            <v-col>
                <!-- Dirección -->
                <v-text-field
                    v-model="editingAddress.street"
                    class="mt-0"
                    filled
                    dense
                    :error="!noError && editingAddress.street == ''"
                    hide-details
                    :label="$t('presupuesto.DireccionCalle') + ` ${!noError ? '*' : ''}`"
                    :loading="loading"
                />
            </v-col>
        </v-row>
        <v-row class="mt-0">
            <v-col>
                <!-- Pais -->
                <v-autocomplete
                    v-model="editingAddress.country"
                    :items="paises"
                    :label="$t('presupuesto.Pais') + ` ${!noError ? '*' : ''}`"
                    filled
                    dense
                    hide-details
                    :error="!noError && editingAddress.country == ''"
                    item-text="label"
                    item-value="id"
                    class="mt-0"
                    :loading="loading"
                ></v-autocomplete>
            </v-col>
            <v-col>
                <!-- Provincia -->
                <v-text-field
                    v-model="editingAddress.state"
                    class="mt-0"
                    filled
                    dense
                    hide-details
                    :error="!noError && editingAddress.state == ''"
                    :label="$t('presupuesto.Poblacion') + ` ${!noError ? '*' : ''}`"
                    :loading="loading"
                />
            </v-col>
        </v-row>
        <v-row class="mt-0">
            <v-col>
                <!-- Ciudad -->
                <v-text-field
                    v-model="editingAddress.city"
                    class="mt-0"
                    filled
                    dense
                    hide-details
                    :error="!noError && editingAddress.city == ''"
                    :label="$t('presupuesto.Ciudad') + ` ${!noError ? '*' : ''}`"
                    :loading="loading"
                />
            </v-col>
            <v-col>
                <!-- Código Postal -->
                <v-text-field
                    v-model="editingAddress.cp"
                    class="mt-0"
                    filled
                    dense
                    hide-details
                    :error="!noError && editingAddress.cp == ''"
                    :label="$t('presupuesto.CP') + ` ${!noError ? '*' : ''}`"
                    :loading="loading"
                />
            </v-col>
        </v-row>
        <v-row class="mt-0">
            <v-col v-if="showEmail">
                <!-- email -->
                <v-text-field
                    id="addressCreatorEmailField"
                    v-model="editingAddress.email"
                    class="mt-0"
                    filled
                    dense
                    hide-details
                    :error="!noError && editingAddress.email == ''"
                    :label="$t('cart.Email')"
                    :loading="loading"
                />
            </v-col>
            <v-col>
                <!-- Teléfono -->
                <v-text-field
                    v-model="editingAddress.phoneNumber"
                    class="mt-0"
                    filled
                    dense
                    hide-details
                    :error="!noError && editingAddress.phoneNumber == ''"
                    :label="$t('presupuesto.Teléfono')"
                    :loading="loading"
                />
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { paises } from "@/utils/addressUtils.js";

export default {
    props: {
        value: {
            type: Object,
            default: () => ({
                street: "",
                cp: "",
                city: "",
                country: "Spain",
                state: "",
                statecode: "",
                district: "",
                email: "",
                phoneNumber: "",
                addresstype: "S"
            })
        },
        avalible: {
            type: Boolean,
            default: true
        },
        loading: {
            type: Boolean,
            default: false
        },
        noError: {
            type: Boolean,
            default: false
        },
        showEmail: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            paises,
            editingAddress: {
                street: "",
                cp: "",
                city: "",
                country: "Spain",
                state: "",
                statecode: "",
                district: "",
                email: "",
                phoneNumber: "",
                addresstype: "S"
            }
        };
    },
    computed: {
        avalibleCustomAddress() {
            return (
                this.editingAddress.state != "" &&
                this.editingAddress.street != "" &&
                this.editingAddress.cp != "" &&
                this.editingAddress.city != "" &&
                this.editingAddress.country != "" &&
                this.editingAddress.email != "" &&
                this.editingAddress.phoneNumber != ""
            );
        }
    },
    watch: {
        editingAddress: {
            handler(newVal) {
                this.$emit("input", newVal);
            },
            deep: true
        },
        avalibleCustomAddress(newVal) {
            this.$emit("update:avalible", newVal);
        }

    },
    mounted() {
        const element = this.$el.querySelector("#addressCreatorEmailField");
        if (element) {
            element.scrollIntoView({
                behavior: "smooth",
                block: "end",
                inline: "end"
            });
        }

        if (this.value) {
            this.editingAddress = {...this.value};
        }
    }
};
</script>
