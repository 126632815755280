<template>
  <v-data-table
    dense
    :headers="headers"
    :items="productosTabla"
    item-key="Referencia"
    :hide-default-footer="true"
    class=""
  ></v-data-table>
</template>
<script>
import {hexToRGBA} from "@/utils/colorUtils";
export default {
  props: {
    headers: {
      type: Array,
      default: new Array(),
      cargando: false,
    },
    productosTabla: {
      type: Array,
      default: new Array(),
      cargando: false,
    },
    Referencia: {
      type: String,
      default: "Id",
      cargando: false,
    },
  },
  data() {
    return {
      colorEven: "#f5f5f5",
    }
  },
  watch: {
    colorEven(newVal) {
      document.documentElement.style.setProperty(
                '--v-secondaryColorPdf-even',
                newVal
            );
    }
  },
  mounted() {
    this.colorEven = hexToRGBA(this.$vuetify.theme.currentTheme.secondaryColorPdf, 0.3)
  },
  
};
</script>
<style scoped lang="scss">

:root {
  --v-secondaryColorPdf-even: var(--v-secondaryColorPdf-base);
}

.v-data-table::v-deep th {
  font-size: 16px !important;
}
.v-data-table::v-deep td {
  font-size: 14px !important;

}

.v-data-table::v-deep tr:nth-of-type(even)
{
  
  background-color: var(--v-secondaryColorPdf-even);
  color: var(--v-secondaryColorText-base, white);
}
</style>
